import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Show } from '../../models/announcement/show.model';
import { PostComment } from '../../models/announcement/post-comment.model';
import { environment } from '../../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })

  export class ShowService {
  //baseUrl = 'https://test-lks-api.uni-systems.ru/announcement';
  baseUrl = environment.announcementApiEndpoint + 'announcement';

  constructor(private http: HttpClient) { }

  public getShow(Id: number) {
    return this.http.get<Show>(this.baseUrl + '/message/' + Id)
      .pipe(
        catchError(this.handleError));
  }

  observer = new Subject();
  public subscriber$ = this.observer.asObservable();
  public Badge(badge: number){
    this.observer.next(badge);  
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}