import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { MiddleControl } from 'src/app/models/progress/middleControl.model';
import { StudentMark } from 'src/app/models/progress/studentMark.model';
import { StudentSemester } from 'src/app/models/progress/studentSemester.model';
import { environment } from 'src/environments/environment';
import { LKAverageStudentMarks } from '../../models/profile/lkAverageStudentMarks.model';

@Injectable({
  providedIn: 'root'
})
export class MiddleControlService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.MiddleControl}`;

  constructor(private http: HttpClient) { }

  //Get MiddleControls by student Id
  public getMiddleControlsByStudentId(studentId: string): Observable<MiddleControl[]> {
    return this.http.get<MiddleControl[]>(this.baseUrl + '/GetMiddleControls/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Get MiddleControls by student semester
  public getMiddleControlsByStudentSemester(studentId: string, studentSemesterId: number): Observable<MiddleControl[]> {
    return this.http.get<MiddleControl[]>(this.baseUrl + '/GetMiddleControlsByStudentSemester/' + studentId + '/' + studentSemesterId)
      .pipe(catchError(this.handleError));
  }

  public getStudentMarksAverageScore(studentId: string): Observable<LKAverageStudentMarks> {
    return this.http.get<LKAverageStudentMarks>(this.baseUrl + '/GetStudentMarksAverageScore/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Get StudentMarks by student semester
  public getStudentMarksByStudentSemester(studentId: string, studentSemesterId: number): Observable<StudentMark[]> {
    return this.http.get<StudentMark[]>(this.baseUrl + '/GetStudentMarksByStudentSemester/' + studentId + '/' + studentSemesterId)
      .pipe(catchError(this.handleError));
  }

  //Get MiddleControls by student Id
  public getStudentSemesters(studentId: string): Observable<StudentSemester[]> {
    return this.http.get<StudentSemester[]>(this.baseUrl + '/GetStudentSemesters/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Get MiddleControl by Id
  public getMiddleControlById(id: string): Observable<MiddleControl> {
    return this.http.get<MiddleControl>(this.baseUrl + '/GetMiddleControlByID/' + id)
      .pipe(
        catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
