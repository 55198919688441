import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictDisplaySettingCategory} from "../../models/home/dictdisplaysettingcategory.model";
import {DictDisplaySystemSettingCategory} from "../../models/home/dictdisplaysystemsettingcategory";

@Injectable({
  providedIn: 'root'
})
export class DisplaySystemSettingCategoryService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.displaysystemsettingcategory}`;

  constructor(private http: HttpClient) { }

  public getDisplaySettingCategories(): Observable<DictDisplaySystemSettingCategory[]> {
    return this.http.get<DictDisplaySettingCategory[]>(this.baseUrl);
  }
}
