<div class="grid-layout-container container">
    <div class = "go-list-news" [routerLink]="['/alert']">
        < Вернуться к списку новостей и объявлений
    </div>

    <div class="announcement">
        <div class="title-organization" >
            <div class="title-news" > {{title}} </div>
        </div>
        <div class="from-date-news"> {{authorFio}} </div>
        <div class="from-date-news"> {{published | date:'dd.MM.yyyy HH:mm'}} </div>
        <kendo-editor  [(ngModel)]="content" [iframe]="false"  [readonly]="true" class="border-none opacity backcolor-transparent">
            <kendo-toolbar class="display-none"></kendo-toolbar>
        </kendo-editor>
        <div class="m-b20 display-table m-l-auto">
            <ng-container  *ngFor="let file of attachments">
                <div class="display-f m-b5 justify-end">
                    <div><span class="k-icon k-i-paperclip m-r5"></span></div>
                    <span style="cursor: pointer;" (click)="getFile(file['externalId'],file['name'])" class="file-name">{{file["name"]}}</span>
                </div>
            </ng-container>
        </div>
        <div  *ngIf="message_type == 1" class="display-f justify-end">
            <div  class="teg" *ngFor="let teg of tags"> 
                #{{teg["title"]}}
            </div>
        </div> 
    </div>


    <div class="title-comments">Добавить новый комментарий</div>

    <div>
        <textarea rows="3" class="text-comments" placeholder="Введите текст комментария" [(ngModel)]="text_comment" >
        </textarea>
        <div class="col-xs-12 col-sm-6 example-col">
            <kendo-fileselect #myFileSelect="kendoFileSelect" class="fileselect"
            [restrictions]="myRestrictions"
            [showFileList]="false"
            (select)="selectEventHandler($event)"
            >
            </kendo-fileselect>
            <p class="file-properties">До 5 файлов формата .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .gif, .tif, .bmp, .png, .zip, .txt с ограничением в 5Мб)</p>
        </div>
        <div class="m-b20">
            <ng-container *ngFor="let file of commentAttachments; let i = index">
                <span class="attachment">
                    {{file['name']}} <a href="#!" class="remove-file" (click)="removeFile(i)">x</a>
                </span>
            </ng-container>
        </div>
    </div>
    <div class="text-r">
        <button kendoButton class = "k-button k-button-sm k-rounded-md k-button-solid-base k-button-solid ng-star-inserted"
                        size="small"
                        themeColor="primary"
                        (click)="sendComment()"
                    >
                отправить
        </button>  
    </div>

    <div class="comments">
        <app-comments [refreshComments]="refreshComments"></app-comments>
    </div>
    
</div>
