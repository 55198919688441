import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKLanguages } from 'src/app/models/profile/lkLanguages.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKLanguagesService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.Languages}`;

  constructor(private http: HttpClient) { }

  //Get Languages
  public getLanguages(studPersonId: string): Observable<LKLanguages[]> {
    return this.http.get<LKLanguages[]>(this.baseUrl + '/GetLanguages/' + studPersonId)
      .pipe(
        catchError(this.handleError));
  }


  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
