<kendo-grid class="grid-form"
            [data]="gridView"
            (add)="addRow()"
            (remove)="removeRow($event)">

  <ng-template *ngIf="editable" kendoGridToolbarTemplate position="bottom">
    <button kendoGridAddCommand icon="plus">Добавить</button>
  </ng-template>

  <kendo-grid-column [width]="200" field="expenseType" title="Виды расходов">
    <ng-template kendoGridCellTemplate let-column="column" let-dataItem="dataItem">
      <span class="field">
        <kendo-textbox (valueChange)="onValueChange()"
                       [disabled]="!editable"
                       [(ngModel)]="dataItem[column.field]" required>
        </kendo-textbox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="quantity" title="Количество единиц (с указанием названия единицы)">
    <ng-template kendoGridCellTemplate let-column="column" let-dataItem="dataItem">
      <span class="field">
        <kendo-textbox (valueChange)="onValueChange()"
                       [disabled]="!editable"
                       [(ngModel)]="dataItem[column.field]" required>
        </kendo-textbox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="costPerStudent" title="Расчет затрат на одного обучающегося (руб.)">
    <ng-template kendoGridCellTemplate let-column="column" let-dataItem="dataItem">
      <span class="field">
        <kendo-numerictextbox (valueChange)="onValueChange()"
                              [disabled]="!editable"
                              [(ngModel)]="dataItem[column.field]" required>
        </kendo-numerictextbox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="costPerGroup" title="Расчет затрат на группу обучающихся (руб.)">
    <ng-template kendoGridCellTemplate let-column="column" let-dataItem="dataItem">
      <span class="field">
        <kendo-numerictextbox (valueChange)="onValueChange()"
                              [disabled]="!editable"
                              [(ngModel)]="dataItem[column.field]" required>
        </kendo-numerictextbox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="totalCost" title="Сумма (руб.)">
    <ng-template kendoGridCellTemplate let-column="column" let-dataItem="dataItem">
      <span class="field">
        <kendo-numerictextbox (valueChange)="onValueChange()"
                              [disabled]="!editable"
                              [(ngModel)]="dataItem[column.field]" required>
        </kendo-numerictextbox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="editable" title="" [width]="100">
    <ng-template kendoGridHeaderTemplate>
    </ng-template>
    <ng-template kendoGridCellTemplate>
      <button kendoGridRemoveCommand icon="close"></button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>