import {Injectable} from '@angular/core';
import {DateFromUTCAsLocal, TimeZoneFix} from '../../helpers/date-helper';
import {KendoProperties} from '../../models/mfc/enums/kendo-properties.enum';
import {
  ApplicationFormPropertyValue, ApplicationPropertyValueDto, DateRangeValue, ExternalFile,
  FilePropertyValue,
  PropertyValue
} from '../../models/mfc/applicationForm/application-property-value.model';
import {
  ApplicationForm,
  ApplicationFormProperty,
  GroupPropertyForm,
  PropertyForm
} from '../../models/mfc/applicationForm/application-form.model';
import {Property} from '../../models/mfc/applicationConstructor/tabs-settings.model';


@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  private mapFormPropertyValue(item: PropertyForm): ApplicationFormProperty {
    return {
      applicationConstructorPropertyId: item.externalId,
      propertyType: item.propertyTypeEnum,
      value: item.propertyValue
    };
  }

  private mapPropertyValueForRequest(item: ApplicationFormProperty): ApplicationFormPropertyValue {
    let value;
    switch (item.propertyType) {
      case KendoProperties.Date:
        value = TimeZoneFix(item.value).toISOString();
        break;
      case KendoProperties.DateRange:
        value = {} as DateRangeValue;
        if ((<DateRangeValue>item.value).dateStart)
          value.dateStart = TimeZoneFix((<DateRangeValue>item.value).dateStart).toISOString();
        if ((<DateRangeValue>item.value).dateEnd)
          value.dateEnd = TimeZoneFix((<DateRangeValue>item.value).dateEnd).toISOString();
        break;
      case KendoProperties.File:
      case KendoProperties.Files:
        value = (<File[]|null>item.value)?.map((item) => {
          const file: FilePropertyValue = {fileName: item.name};
          if (item?.size) file.file = item;
          return file;
        });
        break;
      default:
        value = item.value;
    }
    return {...item, value: <PropertyValue>value};
  }

  private mapPropertyValuesByType(properties: ApplicationFormProperty[]): ApplicationPropertyValueDto {
    const values: ApplicationPropertyValueDto = {};
    properties.forEach((item) => {
      switch (item.propertyType) {
        case KendoProperties.Boolean:
          if (!values.boolProperties) values.boolProperties = [];
          values.boolProperties.push(item);
          break;
        case KendoProperties.Number:
          if (!values.intProperties) values.intProperties = [];
          values.intProperties.push(item);
          break;
        case KendoProperties.Decimal:
          if (!values.decimalProperties) values.decimalProperties = [];
          values.decimalProperties.push(item);
          break;
        case KendoProperties.Date:
          if (!values.dateProperties) values.dateProperties = [];
          values.dateProperties.push(item);
          break;
        case KendoProperties.DateRange:
          if (!values.dateRangeProperties) values.dateRangeProperties = [];
          values.dateRangeProperties.push(item);
          break;
        case KendoProperties.Dropdown:
        case KendoProperties.Combobox:
          if (!values.dictGuidProperties) values.dictGuidProperties = [];
          values.dictGuidProperties.push(item);
          break;
        case KendoProperties.Multiselect:
          if (!values.dictGuidArrayProperties) values.dictGuidArrayProperties = [];
          values.dictGuidArrayProperties.push(item);
          break;
        case KendoProperties.File:
        case KendoProperties.Files:
          if (!values.fileProperties) values.fileProperties = [];
          values.fileProperties.push(item);
          break;
        case KendoProperties.Table:
          if (!values.tableProperties) values.tableProperties = [];
          values.tableProperties.push(item);
          break;
        default:
          if (!values.stringProperties) values.stringProperties = [];
          values.stringProperties.push(item);
      }
    });
    return values;
  }

  private capitalize(key: string) {
    return `${key[0].toUpperCase()}${key.slice(1)}`;
  }

  public valueViewConverter(item: Property, editable: boolean) {
    const defaultValue = editable && item.defaultValue ? item.defaultValue : null;
    switch (item.propertyTypeEnum) {
      case KendoProperties.Textbox:
      case KendoProperties.Textarea:
        return (item.value ? item.value?.stringValue?.value : defaultValue?.string) ?? null;
      case KendoProperties.Number:
        return (item.value ? item.value?.intValue?.value : defaultValue?.int) ?? null;
      case KendoProperties.Decimal:
        return (item.value ? item.value?.decimalValue?.value : defaultValue?.decimal) ?? null;
      case KendoProperties.Date:
        return DateFromUTCAsLocal(item.value ? item.value?.dateValue?.value : defaultValue?.dateTime) ?? null;
      case KendoProperties.DateRange:
        return {
          dateStart: DateFromUTCAsLocal(item.value?.dateRangeValue?.dateStart) ?? null,
          dateEnd: DateFromUTCAsLocal(item.value?.dateRangeValue?.dateEnd) ?? null
        };
      case KendoProperties.Boolean:
        return item.value?.boolValue?.value ?? null;
      case KendoProperties.File:
      case KendoProperties.Files:
        return item.value?.fileValue?.value
          ? item.value?.fileValue?.value.map((item) => {
            const file: ExternalFile = new File([''], item.fileName);
            file.id = item.id;
            return file;
          })
          : null;
      case KendoProperties.Dropdown:
      case KendoProperties.Combobox:
        return (item.value
          ? item.value?.dictGuidValue?.value
          : defaultValue?.dynamicDictValueIds?.length ? defaultValue?.dynamicDictValueIds[0] : null) ?? null;
      case KendoProperties.Multiselect:
        return (item.value
          ? item.value?.dictGuidArrayValue?.value.map((item) => item.value)
          : item.defaultValue?.dynamicDictValueIds) ?? null;
      case KendoProperties.Table:
        return item.value?.tableValue?.value ?? [];
      default:
        return null;
    }
  }

  public mapPropertyValue(item: Property, editable: boolean = false): ApplicationFormProperty {
    return {
      applicationConstructorPropertyId: item.externalId,
      propertyType: item.propertyTypeEnum,
      value: this.valueViewConverter(item, editable && this.getPropertyAccess(item))
    };
  }

  public getPropertyAccess(item: Property) {
    return item.isEditableForAll || item.isEditableForInitiator;
  }

  public mapFormValues(form: GroupPropertyForm[]) {
    return form.flatMap(group => group.properties).map(this.mapFormPropertyValue);
  }

  public getFormData(form: ApplicationForm) {
    form.applicationPropertiesValueDto = form.applicationPropertiesValueDto
      .filter((property) =>
        property.value !== undefined
        && property.value !== null
        && property.value !== ''
        && (property.value instanceof Date
          || typeof property.value !== 'object' || Object.values(property.value).filter((i) => i != null).length)
        && (!Array.isArray(property.value) || property.value.length))
      .map((property) => this.mapPropertyValueForRequest(property));

    const values = this.mapPropertyValuesByType(form.applicationPropertiesValueDto);

    const data = new FormData();
    let valuesKey = '';
    Object.entries(form).forEach(([key, value]) => {
      if (key !== 'applicationPropertiesValueDto') {
        if (['relatedApplicationIds', 'disciplineTableSemesters'].includes(key)) {
          value.forEach((arrayValue: string, i: number) => {
            data.append(`${key}[${i}]`, arrayValue);
          });
          return;
        }
        if (value) data.append(key, value);
      } else {
        valuesKey = this.capitalize(key);
      }
    });

    Object.entries(values).forEach(([key, value]) => {
      (<ApplicationFormProperty[]>value).forEach((item, i) => {
        Object.entries(item).forEach(([itemKey, itemValue]) => {
          const itemKeyName = `${valuesKey}.${this.capitalize(key)}[${i}].${this.capitalize(itemKey)}`;
          if (Array.isArray(itemValue)) {
            itemValue.forEach((arrayValue, j) => {
              if (typeof arrayValue === 'object') {
                Object.entries(arrayValue).forEach(([arrayItemKey, arrayItemValue]) => {
                  const arrayItemKeyName = `${itemKeyName}[${j}].${this.capitalize(arrayItemKey)}`;
                  if (arrayItemValue instanceof File) {
                    data.append(arrayItemKeyName, arrayItemValue, arrayItemValue.name);
                  } else {
                    data.append(arrayItemKeyName, <string>arrayItemValue);
                  }
                });
              } else {
                data.append(`${itemKeyName}[${j}]`, arrayValue);
              }
            });
          } else if (typeof itemValue === 'object') {
            const itemKeyName = `${valuesKey}.${this.capitalize(key)}[${i}]`;
            Object.entries(itemValue).forEach(([objectItemKey, objectItemValue]) => {
              const objectItemKeyName = `${itemKeyName}.${this.capitalize(objectItemKey)}`;
              data.append(objectItemKeyName, <string>objectItemValue);
            });
          } else {
            data.append(itemKeyName, itemValue);
          }
        });
      });
    });
    return data;
  }
}
