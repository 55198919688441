import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AlertStatistics } from '../../models/announcement/alert-statistics.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })

  export class AlertStatisticsService {

    baseUrl = environment.announcementApiEndpoint + 'announcement';
  //baseUrl = 'https://test-lks-api.uni-systems.ru/announcement';


  constructor(private http: HttpClient) { }

  public getAlertStatistics() {
    return this.http.get<AlertStatistics>(this.baseUrl + '/statistics' )
      .pipe(
        catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}