import { Component, OnInit, Injectable} from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Show } from 'src/app/models/announcement/show.model';
import { ShowService } from 'src/app/services/announcement/show.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { PostComment } from 'src/app/models/announcement/post-comment.model';
import  {Router, RouterLink} from "@angular/router";
import { CommentsService } from 'src/app/services/announcement/comments.service';
import { FileRestrictions, SelectEvent, FileSelectComponent } from "@progress/kendo-angular-upload";
import { NgTemplateOutlet } from '@angular/common';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { Role } from 'src/app/models/useraccess/role';
import { NotificationsService } from "../../../services/Notifications/notifications.service";

@Component({
    selector: 'app-show',
    templateUrl: './show.component.html',
    styleUrls: ['./show.component.scss'],

})

@Injectable()
export class ShowComponent  implements OnInit{

    baseUrl = environment.announcementApiEndpoint + 'announcement';

    public id: number | undefined;
    public messageId: number = 0;

    public show: Show | undefined;

    public title: string = "";
    public content: string = "";
    public authorFio: string = "";
    public userId: number = 0;
    public authorId: number = 0;
    public published : Date = new Date();
    public attachments: [] = [];
    public message_type : number = 0;
    public tags: Show["tags"] = [];
    public post: string = "";
    public refreshComments: number = 0;
    public refreshBadge: number = 0;

    public myRestrictions: FileRestrictions = {
      maxFileSize: environment.announcement.commentMaxFileSize,
      allowedExtensions: [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".jpeg", ".gif", ".tif", ".bmp", ".png", ".zip", ".txt"],
    };

    constructor(
        private activatedroute: ActivatedRoute,
        private showService: ShowService,
        private http: HttpClient,
        private router: Router,
        private commentsService: CommentsService,
        private jwtHelper: JwtHelperService,
        private tokenStore: TokenStorageService,
        private notificationService: NotificationsService,
      ) {
      }

    public getShow(id: number) {
      this.showService.getShow(id)
        .subscribe(
          response => {
          this.show = response;
          this.userId = this.show.userId;
          this.authorId = this.show.authorId;
          this.title = this.show.title;
          this.content = this.show.content;
          this.authorFio = this.show.authorFio;
          this.published = this.show.published;
          this.attachments = this.show.attachments;
          this.message_type = this.show.messageType;
          this.tags = this.show.tags;
          this.post = this.show.post;
          this.messageId = +id;

          let refreshBadge = 0;
          this.showService.Badge(refreshBadge + 1);
        }
        );
    }

      public text_comment: string = "";
      public commentAttachments = new Array();

      public sendComment(){
        if(this.commentAttachments.length == 0 && this.text_comment.trim() == ""){
          this.notificationService.showError("Комментарий не должен быть пустым");
        }
        else{
          var formData = new FormData();
          formData.append('messageId', this.messageId.toString());
          formData.append('text', this.text_comment);
          this.commentAttachments.forEach(element => {
            formData.append('commentAttachments', element);
          });

          this.http.post(this.baseUrl + '/student/comment', formData).subscribe(() => {
            this.refreshComments++;
          });

          this.text_comment = "";
          this.commentAttachments.length = 0;
        }
      }

      public selectEventHandler(e: SelectEvent){

        for (let i = 0; i < e.files.length; i++){
          if(e.files[i].validationErrors == undefined) this.commentAttachments.push(e.files[i].rawFile)
          else this.notificationService.showError("Файл данного формата добавить нельзя");
        }
        if(this.commentAttachments.length > 5){
          this.notificationService.showError("Можно добавить не более 5 файлов");
          this.commentAttachments.length = 5;
        }
      }

      public removeFile(i : number) {
        this.commentAttachments.splice(i, 1);
        return false;
      }

      public getFile(fileid: string, filename: string){

        var url = this.baseUrl + "/getfile?fileid=" + fileid + "&filename=" + filename;
        this.http.get(url, { responseType: 'blob' }).subscribe(
          (response) => {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            if (filename) {
              downloadLink.setAttribute('download', filename);
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink)
          }
        )
      }

      ngOnInit() {
      this.getShow(this.activatedroute.snapshot.params['id']);
    }
}
