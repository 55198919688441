import { Component, OnInit } from '@angular/core';
import { items } from 'src/app/models/home/menu-items';

@Component({
  selector: 'home-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  //Menu items
  public items = items;

  constructor() { }

  ngOnInit(): void {
  }

}
