<kendo-grid class="grid-form"
            [data]="data"
            (add)="addRow()"
            (remove)="removeRow($event)">

  <ng-template *ngIf="editable" kendoGridToolbarTemplate position="bottom">
    <button kendoGridAddCommand icon="plus">Добавить</button>
  </ng-template>

  <kendo-grid-column [width]="200" field="fullName" title="ФИО">
    <ng-template kendoGridCellTemplate let-column="column" let-dataItem="dataItem">
      <span class="field">
        <kendo-textbox [(ngModel)]="dataItem[column.field]"
                       [disabled]="!editable"
                       (valueChange)="onValueChange()" required>
        </kendo-textbox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="200" field="facultyId" title="Факультет">
    <ng-template kendoGridCellTemplate let-column="column" let-dataItem="dataItem">
      <span class="field">
        <kendo-combobox
                [data]="faculties"
                [(ngModel)]="dataItem[column.field]"
                [valuePrimitive]="true"
                [kendoDropDownFilter]="filterSettings"
                [disabled]="!editable"
                (valueChange)="onValueChange()"
                valueField="id"
                textField="shortName"
                required>
        </kendo-combobox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="200" field="eduGroupName" title="Группа">
    <ng-template kendoGridCellTemplate let-column="column" let-dataItem="dataItem">
      <span class="field">
        <kendo-textbox [(ngModel)]="dataItem[column.field]"
                       [disabled]="!editable"
                       (valueChange)="onValueChange()" required>
        </kendo-textbox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="editable" title="" [width]="100">
    <ng-template kendoGridCellTemplate>
      <button kendoGridRemoveCommand icon="close"></button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
