<h2>Просмотр от другого лица</h2>
<button (click)="onCancel()" kendoButton>
    Отмена
</button>
<kendo-tilelayout style="padding: 16px 16px 16px 0">
    <kendo-tilelayout-item>
        <kendo-tilelayout-item-body>
            <h3>Поиск студента</h3>
            <div class="item">
                <h3>ФИО</h3>
                <kendo-combobox 
                style="width: 40%"
                [kendoDropDownFilter]="filterSettings"
                [data]="studEduGroups"
                [virtual]="virtual"
                textField="fullName"
                valueField="studentId"
                [formControl]="$any(formGroup).controls['studentId']"
                [valuePrimitive]="true">
                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                        <span class="comboboxItem">
                            <span class="comboChild fullName">{{ dataItem.fullName + " "}}</span>
                            <span class="comboChild info">{{dataItem.facultyName}}, {{dataItem.groupName}}</span>
                        </span>
                    </ng-template>
                </kendo-combobox>
                <button kendoButton [disabled]="!formGroup.valid" (click)="switchStudent()">Перейти к просмотру</button>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>