import {Component, OnInit, ViewChild} from "@angular/core";
import {EmploymentService} from "../../services/employment/employment.service";
import {NotificationsService} from "../../services/Notifications/notifications.service";
import {Employment} from "../../models/employment/employment.model";
import {ActivatedRoute, Router} from "@angular/router";
import {LKStudentService} from "../../services/LKStudent/lkstudent.service";
import {LKStudPerson} from "../../models/profile/lkStudPerson.model";
import {LKStudent} from "../../models/profile/lkstudent.model";
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {LKDictOrganizationService} from "../../services/LKStudent/dictionaries/lkdict-organization.service";
import {LKDictOrganization} from "../../models/profile/dictionaries/lkDictOrganization.model";
import {DictEmployerPost} from "../../models/employment/dictEmployerPost.model";
import {DictEmployerPostService} from "../../services/employment/dictEmployerPost.service";
import {DateToString} from "../../helpers/date-helper";
import {saveAs} from "@progress/kendo-file-saver";
import {openRemoveDialog} from "../../helpers/dialog-helper";

@Component({
  selector: 'employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.scss']
})
export class EmploymentComponent implements OnInit {

  public studentId!: string;

  public loading = false;
  public editMode = false;
  public skip = 0;
  public formGroup: UntypedFormGroup | undefined;
  public editable?: boolean = true

  public employments: Employment[] = [];
  public organizations: LKDictOrganization[] = [];
  public employerPosts: DictEmployerPost[] = [];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isLine = false;
  public opened = false;

  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "",
    studPersonId: "",
    studPerson: this.studPerson
  }

  async ngOnInit() {
    await this.getStudentByLogin();
    this.getAllOrganizations();
    this.getAllEmployerPosts();
    this.getAllStudentEmployments(this.studentId);
  }

  constructor(
    private activateRoute: ActivatedRoute,
    private studentService: LKStudentService,
    private employmentService: EmploymentService,
    private organizationService: LKDictOrganizationService,
    private employerPostService: DictEmployerPostService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined;
  }

  public toLocalDatetime(dt: Date) {
    return dt != undefined ? DateToString(dt,'dd.MM.yyyy') : '-';
  }

  public nameOrganization(value: string) {
    return this.organizations.find((x) => x.id === value)?.organizationName ?? "";
  }

  public nameEmployerPost(value: string) {
    return this.employerPosts.find((x) => x.id === value)?.postName ?? "";
  }

  private getAllOrganizations() {
    this.organizationService.getDictOrganizations().subscribe({
      next: response => this.organizations = response,
      error: err => {
        this.notificationService.showError(err);
      }
    })
  }

  private getAllEmployerPosts() {
    this.employerPostService.getAllEmployerPosts().subscribe({
      next: response => this.employerPosts = response,
      error: err => {
        this.notificationService.showError(err);
      }
    })
  }

  public async getStudentByLogin() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    await this.studentService.getCurrentStudent().toPromise().then(response => {
      if (!response) return;

      this.students = response;
      this.studentModel = changeStudent ? this.students[changeStudent-1] : this.students[0];
      this.studentId = this.studentModel.externalId;
    }, reason => {
      this.notificationService.showError('Не удалось загрузить информацию о студенте для просмотра');
    });
  }

  public getAllStudentEmployments(studentId: string) : void {
    this.employmentService.getEmployments(studentId).subscribe({
      next: response => this.employments = response,
      error: err => {
        this.notificationService.showError(err);
      }
    })
  }

  public moveToInfo() : void {
    this.router.navigate(['employment/employmentInfo']);
  }

  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public editHandler({ dataItem }: CellClickEvent): void {
    this.router.navigate(['employment/employmentInfo' + `/${dataItem.id}`]);
  }

  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  private saveRow(): void {
    if (this.isInEditingMode) {
      //Update dictionary
      if (this.formGroup !== undefined) {
        this.formGroup.value.studentId = this.studentId;
        this.employmentService.updateEmployment(this.formGroup.value.id, this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllStudentEmployments(this.studentId);
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  private closeEditor(grid: GridComponent, rowIndex: number | undefined = this.editedRowIndex): void
  {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, "запись о трудовой деятельности");

    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        this.opened = false;
        if (result.text == "Да") {
          this.employmentService.deleteEmployment(dataItem.id)
            .subscribe({
                next: () => {
                  this.getAllStudentEmployments(this.studentId);
                  this.notificationService.showSuccess("Удалено");
                },
                error: (error) => {
                  this.notificationService.showError(error.error);
                }
              }
            );
        }
      }
    });
  }

  public downloadFile(id: string) {
    const fileName = this.employments.find((employment: Employment) => employment.scanFile.id == id)?.scanFile.fileFullName;
    if(fileName) {
      this.employmentService.getEmploymentFile(id).subscribe(
        (response: BlobPart) => {
          const blob: any = new Blob([response], { type: `Content-Type', 'application/octet-stream` });

          saveAs(blob, fileName);
        }
      );
    }
  }
}
