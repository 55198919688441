export const items = [
  {
    text: "Настройки",
    icon: "cog",
    items: [
      {
        text: "Настройки отображения полей",
        path: "/home/studentcardsettings"
      }
    ]
  }
];
