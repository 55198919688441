import {Injectable} from "@angular/core";
import {BehaviorSubject} from 'rxjs';
import {PropertiesService} from './properties.service';
import {ApplicationFormProperty, ApplicationTabData} from '../../models/mfc/applicationForm/application-form.model';
import {TabsSettings} from '../../models/mfc/applicationConstructor/tabs-settings.model';
import {ApplicationApprovalSettings} from '../../models/mfc/applicationForm/approvalList.model';
import {KendoProperties} from "../../models/mfc/enums/kendo-properties.enum";
import {EnableDisciplineTable} from "../../models/mfc/enums/enable-discipline-table.enum";
import {editableStatuses} from '../../models/mfc/dicts/application-status.model';
import {ApplicationStatusesEnum} from '../../models/mfc/enums/application-statuses.enum';

@Injectable({
  providedIn: 'root'
})
export class ApplicationEditFormService {

  private tabsData: ApplicationTabData[] = [];
  private hasChanges = false;
  private isAdd = true;

  public isValid = true;
  public disciplineTableSemesters: number[] = [];
  public enableDisciplineTable?: EnableDisciplineTable;
  public studentId?: string;
  public currentTab = 0;
  public editable?: boolean;

  public save$ = new BehaviorSubject<number|null>(null);
  public checkChanges$ = new BehaviorSubject<number|null>(null);

  constructor(
    private propertiesService: PropertiesService
  ) { }

  private mapTabsValues(tabs: TabsSettings[], startIndex: number): ApplicationTabData[] {
    const data: ApplicationTabData[] = [];
    tabs.forEach((tab, index) => {
      const values: ApplicationFormProperty[] = [];
      tab.groupProperties.forEach((group) => {
        group.standardProperties.forEach((property) => {
          values.push(this.propertiesService.mapPropertyValue(property, this.editable));
        });
        group.additionalProperties.forEach((property) => {
          values.push(this.propertiesService.mapPropertyValue(property, this.editable));
        });
      });
      data.push({index: index + startIndex, applicationPropertiesValueDto: values});
    });
    return data;
  }

  public setTabsData(tabs: TabsSettings[], startIndex: number) {
    this.tabsData = this.mapTabsValues(tabs, startIndex);
  }

  public getTabsValues(): ApplicationFormProperty[] {
    return this.tabsData.flatMap((item) => item.applicationPropertiesValueDto)
      .map(item => ({
        ...item,
        value: item.propertyType === KendoProperties.Table
          ? JSON.stringify(item.value ?? [])
          : item.value
      }));
  }

  public saveTabData(index: number, data: ApplicationFormProperty[]) {
    const tabIndex = this.tabsData.findIndex((item) => item.index === index);
    if (tabIndex >= 0) {
      this.tabsData[tabIndex].applicationPropertiesValueDto = data;
    } else {
      this.tabsData.push({index, applicationPropertiesValueDto: data});
    }
  }

  public getTabValues(index: number) {
    return this.tabsData.find((item) => item.index === index)?.applicationPropertiesValueDto;
  }

  public clearTabsData() {
    this.tabsData = [];
  }

  public setHasChanges(value: boolean) {
    this.hasChanges = this.hasChanges || value;
  }

  public resetHasChanges() {
    this.hasChanges = false;
  }

  public hasFormChanges(index: number) {
    this.checkChanges$.next(index);
    return this.hasChanges;
  }

  public mapApprovalSettings(settings: ApplicationApprovalSettings[]) {
    let index = settings.map(a =>
      a.completedApprovalStep?.approvalResult).lastIndexOf(true);

    if (index < 0) {
      index = 0;
    }

    let currentStage = settings[index].numberInApprovalSequence;

    const allApproved = settings.filter(a => a.numberInApprovalSequence
      === currentStage && !a.completedApprovalStep?.approvalResult).length === 0;

    if (allApproved) {
      const currentIndex = settings.map(item => item.numberInApprovalSequence).lastIndexOf(currentStage) + 1;
      currentStage = settings[currentIndex] ? settings[currentIndex].numberInApprovalSequence : currentStage++;
    }

    settings.forEach((item) => {
      item.isCurrentStage = (item.numberInApprovalSequence === currentStage && !item.completedApprovalStep?.approvalResult);
    });

    return settings;
  }

  public isApplicationEditable(status: ApplicationStatusesEnum) {
    this.isAdd = false;
    this.editable = editableStatuses.includes(status);
    return this.editable;
  }
}
