<h5 class="achievements-title">Портфолио</h5>
<div *ngIf="!editMode">
  <div class="achievementBlock" *ngFor="let activity of directionActivitiesByFilter">
    <h5 class="title m-b15">{{activity.name}}</h5>
    <button kendoButton class="achievements-title" themeColor="success"
            (click)="addAchievement(activity.id)">
      Добавить
      <span class="k-icon k-i-plus ml-8"></span>
    </button>
    <div *ngIf="getAchievementsByActivityId(activity.id).length > 0; else noAchievements">
      <div class="activity m-b15">
        <div>
          <p class="learning-text"><b class="boldText">Подача заявлений обучающимися:</b> <span class="learning">
            {{ getDate(getAchievementsByActivityId(activity.id)[0].achievementPeriod.receiptionStartDate) }} - {{ getDate(getAchievementsByActivityId(activity.id)[0].achievementPeriod.receiptionEndDate)}}
          </span></p>
          <p class="learning-text"><b class="boldText">Утверждение портфолио в деканате (дирекции):</b> <span class="learning">
            {{ getDate(getAchievementsByActivityId(activity.id)[0].achievementPeriod.checkingStartDate) }} - {{ getDate(getAchievementsByActivityId(activity.id)[0].achievementPeriod.checkingEndDate)}}
          </span></p>
          <p class="learning-text"><b class="boldText">Период действия нового рейтинга:</b> <span class="learning">
            {{ getDate(getAchievementsByActivityId(activity.id)[0].achievementPeriod.pretendentStartDate) }} - {{ getDate(getAchievementsByActivityId(activity.id)[0].achievementPeriod.pretendentEndDate)}}
          </span></p>
        </div>
        <div>
          <p class="learning-text m-b15"><b>Общий балл (балл в рейтинг ПГАС)</b></p>
          <div class="pgasPoints">
            <div *ngFor="let totalMark of getAchievementsByActivityId(activity.id)[0].totalMarks">
              <div class="totalMark" [style.background]=totalMark.color title={{totalMark.name}}>{{totalMark.mark}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="hide m-b15" (click)="hidePast(activity.id, getAchievementsByActivityId(activity.id)[0].hideAchievements)" *ngIf="getAchievementsByActivityId(activity.id)[0].hideAchievements">
        {{getAchievementsByActivityId(activity.id)[0].hideAchievements ? "Отобразить истекшие" : "Скрыть истекшие"}}
      </div>
      <kendo-grid class="m-b15"
                  [data]="getAchievementsByActivityId(activity.id)[0].achievementList"
                  (cellClick)="viewHandler($event)">
        <kendo-grid-column field="status" title="Статус" [width]="130" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p class="left-alignment">{{dataItem.dictAchievementStatusName}}</p>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="name" title="Название" [width]="200" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p>{{dataItem.name}}</p>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="type" title="Тип" [width]="130" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p class="left-alignment">{{dataItem.dictWorkTypeName}}</p>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="mark" title="Балл" [width]="100" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p class="center-alignment">{{dataItem.mark}}</p>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column-group headerClass="grid-header" title="Действует">
          <kendo-grid-column field="dateStart" title="с" [width]="120" headerClass="grid-header">
            <ng-template kendoGridCellTemplate let-dataItem>
              <p>{{dataItem.dateStart | date:'dd.MM.yyyy'}}</p>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="dateEnd" title="до" [width]="120" headerClass="grid-header">
            <ng-template kendoGridCellTemplate let-dataItem>
              <p>{{dataItem.dateEnd | date:'dd.MM.yyyy'}}</p>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>
        <kendo-grid-column field="comment" title="Примечание" [width]="200" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p class="left-alignment">{{dataItem.comment}}</p>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="scan" title="Скан. копия" [width]="80" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton size="small" icon="download" *ngIf="dataItem.files && dataItem.files.length > 0" (click)="downloadFile(dataItem.files[0])"></button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <ng-template #noAchievements>
      <p class="learning-text">В данной категории нет добавленных достижений</p>
    </ng-template>
  </div>
</div>

<!---Edit-->
<div class="container" *ngIf="editMode">
  <form novalidate class="k-form m-b15" [formGroup]="editForm">
    <h5 class="title m-b15">Редактирование работы</h5>
    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Статус</h2>
        <kendo-textbox [disabled]="true" class="w-400" formControlName="dictAchievementStatusName">
        </kendo-textbox>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Направление деятельности</h2>
        <kendo-dropdownlist class="w-400"
                            textField="name"
                            valueField="id"
                            required
                            formControlName="directionActivityId"
                            [disabled]="!editable"
                            (valueChange)="valueChangeDirectionActivity($event)"
                            [data]="directionActivitiesByFilter"
                            [valuePrimitive]="true">
        </kendo-dropdownlist>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Название работы/мероприятия/достижения</h2>
        <kendo-textbox formControlName="name"
                       required
                       class="w-400"
                       [disabled]="!editable"
                       (valueChange)="formChanged()"
        ></kendo-textbox>
        <span class="red">*</span>
      </div>
      <kendo-formerror class="m-l400">Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>

      <kendo-formfield>
        <div class="portfolioField">
          <h2 class="portfolioField__title">Тип работы</h2>
          <kendo-dropdowntree kendoDropDownTreeExpandable
                              class="w-400"
                              [data]="workTypesTree"
                              [dataItem]="selectedWorkTypesTree || {}"
                              [formControl]="$any(editForm).controls['dictWorkTypeId']"
                              textField="name"
                              valueField="id"
                              formControlName="dictWorkTypeId"
                              (valueChange)="valueChangeType($event)"
                              [fetchChildren]="children"
                              [hasChildren]="hasChildren"
                              [valuePrimitive]="true"
                              [disabled]="!editable"
          >
          </kendo-dropdowntree>
        </div>
      </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Балл</h2>
        <kendo-textbox [disabled]="true"  class="w-400" formControlName="mark" [(ngModel)]="mark">
        </kendo-textbox>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Коэффициент</h2>
        <div kendoTooltip position="right" tooltipClass="tooltip" [tooltipWidth]="300" style="color:black !important">
          <kendo-numerictextbox title = "Если необходимо разделить баллы, укажите коэффициент здесь.
                                         Например, чтобы разделить баллы между 3 соавторами, напишите 0,33.
                                        Если ВУЗ не является организатором или участником мероприятия, укажите коэффициент 0,5
                                        (в части общественной, культурно-творческой и спортивной деятльности)."
                                class="w-400" required
                                formControlName="coefficient"
                                (valueChange)="onChangeCoefficient()"
                                [min]="0"
                                [disabled]="!editable"
                                [(ngModel)]="coefficient">
          </kendo-numerictextbox>
          <span class="red">*</span>
        </div>
      </div>
      <kendo-formerror class="m-l400">Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Балл с учетом коэффициента</h2>
        <kendo-textbox [disabled]="true"
                       class="w-400"
                       formControlName="markWithCoefficient"
                       [(ngModel)]="markWithCoefficient">
        </kendo-textbox>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Действует с</h2>
        <kendo-datepicker  class="w-400" [disabled]="!editable"
          #dateStart
          format="dd.MM.yyyy"
          [openOnFocus]="dateStart"
          formControlName="dateStart"
          (valueChange)="formChanged()"
          required
        >
        </kendo-datepicker>
        <span class="red">*</span>
      </div>
      <kendo-formerror class="m-l400">Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Действует до</h2>
        <kendo-datepicker  class="w-400" [disabled]="!editable"
          #dateEnd
          format="dd.MM.yyyy"
          [openOnFocus]="dateEnd"
          formControlName="dateEnd"
          (valueChange)="formChanged()"
          required
        >
        </kendo-datepicker>
        <span class="red">*</span>
      </div>
      <kendo-formerror class="m-l400">Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Примечание</h2>
        <kendo-textarea class="w-400" [rows]="3" [disabled]="!editable"
          formControlName="comment"
          (valueChange)="formChanged()"
        >
        </kendo-textarea>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Сканированная копия</h2>
        <div class="file-select">
          <kendo-fileselect style="width: 400px"
                            [restrictions]="restrictions"
                            [multiple]="false"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="fileUploads"
                            (select)="fileSelected()"
                            (remove)="fileRemoved()"
                            [disabled]="!editable">
            <kendo-fileselect-messages
              invalidFileExtension="{{invalidFileExtensionText}}"
              [invalidMaxFileSize]="maxFileSizeMessage">
            </kendo-fileselect-messages>
          </kendo-fileselect>
          <span class="k-display-flex link"
                *ngFor='let file of fileUploadedList'
                (click)="downloadFile(file)">
            <span class="k-icon k-i-file"></span>
            <h2 class="link">{{file.name}}</h2>
          </span>
        </div>
      </div>
    </kendo-formfield>
    <h2 class="warning" *ngIf="!fileUploaded">* Загрузите скан.копию для добавления нового достижения</h2>
  </form>
  <div class="buttonsBlock">
    <button kendoButton class="m-l15" (click)="cancelEdit()">Закрыть</button>
    <button kendoButton class="saveButton m-l15" (click)="saveAchievement()" *ngIf="editable && !achievementStatusReturned" themeColor="primary">Сохранить</button>
    <button kendoButton class="saveButton m-l15" (click)="saveAchievement()" *ngIf="achievementStatusReturned">Сохранить</button>
    <button kendoButton class="saveButton m-l15" (click)="saveReturnedAchievement()" themeColor="primary" *ngIf="achievementStatusReturned">Сохранить и отправить</button>
    <button kendoButton (click)="deleteAchievement()" themeColor="secondary" *ngIf="showDeleteButton() && editable">Удалить</button>
  </div>
</div>
<div kendoDialogContainer></div>
