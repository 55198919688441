import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKEducationPlan } from 'src/app/models/education/LKEducationPlan.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKEducationPlanService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.EducationBUP}`;

  constructor(private http: HttpClient) { }

  //Get all EducationPlans
  public getAllEducationPlans(): Observable<LKEducationPlan[]> {
    return this.http.get<LKEducationPlan[]>(this.baseUrl + '/GetEducationPlans/');
  }

  //Get EducationPlan by StudentId
  public getEducationPlanByStudentId(studentId: string) {
    return this.http.get<LKEducationPlan>(this.baseUrl + '/GetEducationPlanByStudentID/' + studentId)
      .pipe(
        catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}