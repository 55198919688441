export enum DisplaySystemSettingEnumList {
  //Настройки контактов
  mobile_phone = 1,
  city_phone,
  email,
  socialnetwork,
  student_can_edit_deanery_contacts,

  //Настройки портфолио
  portfolio
}

export const DisplaySystemSettingEnum = DisplaySystemSettingEnumList
