import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ApplicationCategoryTree} from '../../../models/mfc/application-categories-tree.model';
import {Router} from '@angular/router';
import {ApplicationTypeSelectService} from '../../../services/mfc/application-type-select.service';


@Component({
  selector: 'mfc-application-type-select',
  templateUrl: './application-type-select.component.html',
  styleUrls: ['./application-type-select.component.scss']
})
export class AppicationTypeSelectComponent implements OnInit {
  //Добавляется вторая кнопка Назад, если при прокрутке скрывается первая
  protected contentHeightCoefficient = 0.9;
  protected data: ApplicationCategoryTree[] = [];

  protected readonly window = window;

  constructor(
    private location: Location,
    private router: Router,
    private applicationTypeSelectService: ApplicationTypeSelectService
  ) { }

  ngOnInit() {
    this.getTree();
  }

  private getTree() {
    this.applicationTypeSelectService.getCategoriesTree().subscribe((response) => {
      this.data = response;
      this.data.forEach((category) =>
        category.dictApplicationTypes = category.dictApplicationTypes.filter((type) => type.displayInAddForm));
    });
  }

  protected createApplication(categoryId: string, typeId?: string) {
    this.router.navigate(['/mfc/applicationForm'], {queryParams: {categoryId: categoryId, typeId: typeId}});
  }

  protected goBack() {
    this.location.back();
  }
}
