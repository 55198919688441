import {ControlAction} from "../enums/control-action.enum";
import {DictCycleType} from "../enums/cycle-type.enum";
import {DictDisciplineTypeEnum} from "../../enums/discipline-type.enum";

export class DisciplineTable {
  id = '';
  dictDisciplineId = '';
  name? = '';
  printName? = '';
  hours = 0;
  dictMarkId? = '';
  sortNumber? = 0;
  canEditMark? = false;
  laborIntensity = 0;
  isGroup? = false;
  controlActionEnum?: ControlAction = ControlAction.NoControlAction;
  dictCycleTypeEnum?: DictCycleType = 0;
  dictDisciplineTypeEnum?: DictDisciplineTypeEnum;
  dictDisciplineName?: string;
  dictDisciplineTypeName?: string;
}

export class DisciplineTableByCycleType extends DisciplineTable {
  courseWorkOrCourseProject = false;
  faculty = false;
  hoursContactWork = 0;
}

export class DisciplineTableBySemester extends DisciplineTable {
  semester = 0;
}

export class DisciplineTableFull extends DisciplineTableByCycleType {
  semester?: number;
}