<h1>Настройки отображения полей</h1>

<kendo-tabstrip class="custom-tabs k-mt-4">
  <kendo-tabstrip-tab *ngFor="let item of tabItems; let i = index"
                      [title]="item.title"
                      [selected]="i === selected">
    <ng-template kendoTabContent>

      <div class="content-container" [hidden]="i != 0">

        <div class="title">
          <h3>Выбор полей для отображения</h3>
          <div class="functionButtons editModeButtons absolute-buttons">
            <button kendoButton
                    size="small"
                    (click)="closeHandler()">
              Закрыть
            </button>
            <button kendoButton
                    size="small"
                    themeColor="primary"
                    (click)="applyHandlerDisplaySetting()">
              Применить
            </button>
          </div>
        </div>

        <!--Персональные данные-->
        <div class="content" *ngFor = "let category of displayCategory; let c = index">
          <h2>{{category.name}}</h2>
          <div class="grid-box">
            <ng-container *ngFor = "let item of displaySettings; let i = index">
              <div class="child" *ngIf="category.externalId === item.dictDisplaySettingCategoryId">
                <input type="checkbox" kendoCheckBox [(ngModel)]="item.isDisplay" class="input-check"/>
                <kendo-label class="input-label">{{item.name}}</kendo-label>
              </div>
            </ng-container>
          </div>
        </div>

        <!--Buttons-->
      </div>

      <div class="content-container" [hidden]="i != 1">

        <div class="content" *ngFor="let category of displaySystemSettingCategory; let c = index">

          <div class="contactSettingsContainer" *ngIf="category.category === systemCategoryEnum.contacts">
            <h2>{{category.name}}</h2>
            <div class="cantEdit">
              <div class="inputBlock">
                <input
                  type="radio"
                  name="contact"
                  value="canNotEdit"
                  kendoRadioButton
                  (change)="onValueChange($event)"
                  [checked]="isCantEditSelected()"
                />
              </div>
              <div class="infoBlock">
                <p>Обучающийся <b>не</b> может добавлять, редактировать и удалять контакты</p>
                <p class="text-area">Текст пометки для студента:</p>
                <textarea [(ngModel)]="systemSettingContactNotification.systemsSettingContactNotification" [value]="systemSettingContactNotification.systemsSettingContactNotification" class="text-comments">
                </textarea>
              </div>
            </div>
            <div class="canEdit">
              <div class="inputBlock">
                <input
                  type="radio"
                  name="contact"
                  value="canEdit"
                  kendoRadioButton
                  (change)="onValueChange($event)"
                  [checked]="!isCantEditSelected()"
                />
              </div>
              <div class="canEditInfoBlock">
                <div class="canEditContactsCheckBoxs">
                  <p>Обучающийся может добавлять, редактировать и удалять контакты:</p>
                  <div class="contactsInputBlock">
                    <div *ngFor="let item of displaySystemSetting; let i = index">
                      <div *ngIf="item.category === category.category && item.type !== systemSetting.student_can_edit_deanery_contacts">
                        <input type="checkbox" kendoCheckBox [(ngModel)]="item.isDisplay" class="input-check">
                        <kendo-label class="input-label">{{item.name}}</kendo-label>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let item of displaySystemSetting; let i = index">
                  <div class="columnChild" *ngIf="item.type === systemSetting.student_can_edit_deanery_contacts">
                    <input type="checkbox" kendoCheckBox [(ngModel)]="item.isDisplay" class="input-check">
                    <kendo-label class="input-label">{{item.name}}</kendo-label>
                  </div>
                  <p class="canEditNote" *ngIf="item.type === systemSetting.student_can_edit_deanery_contacts">
                    * Настройка включает возможность смены “основной” электронной почты студента. Основной почтой считается электронная почта, на которую приходят уведомления и письма подтверждения.<br>
                    Добавление основной почты студентом происходит только с подтверждением почты по проверочному коду. Остальные контакты добавляются без проверки.</p>
                </ng-container>
                <div class="emailMessage">
                  <p class="text-area">Текст письма с проверочным кодом:</p>
                  <textarea [(ngModel)]="emailNotification.notificationText" placeholder="Текст письма с проверочным кодом" [value]="emailNotification.notificationText" class="emailMessageArea">
                  </textarea>
                  <p class="emailMessageTooltip">
                    * Текст обязательно должен содержать параметр <span class="fw-700">#код</span>
                  </p>
                </div>
              </div>
            </div>

          </div>

          <div class="portfolio" *ngIf="category.category === systemCategoryEnum.portfolio">
            <h2>{{category.name}}</h2>
            <ng-container *ngFor="let item of displaySystemSetting; let i = index">
              <div class="child" *ngIf="item.category === category.category">
                <input type="checkbox" kendoCheckBox [(ngModel)]="item.isDisplay" class="input-check">
                <kendo-label class="input-label">{{item.name}}</kendo-label>
              </div>
              <p class="portfolioNote" *ngIf="item.type === systemSetting.portfolio">
                * Настройка функции отправки обучающимся достижений на проверку, обработка достижений сотрудником деканата/дирекции,<br>
                расчет суммы баллов за достижения. Возможность отправить данные для расчета стипендии во внешние системы.
              </p>
            </ng-container>
          </div>

        </div>



        <div class="functionButtons editModeButtons absolute-buttons">
          <button kendoButton
                  size="small"
                  (click)="closeHandler()">
            Закрыть
          </button>
          <button kendoButton
                  size="small"
                  themeColor="primary"
                  (click)="applyHandlerSystemSetting()">
            Применить
          </button>
        </div>
      </div>

    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>



