<div
  [class]="{
    week: true,
    'week--bold': false&&isSemesterBegin,
    'week--selected': isSelectedCell && isWholeWeek,
    'week--gray': isSelectedCell && !isWholeWeek
  }"
>
  <div
    *ngIf="weekInfo.days.length === 1 && isWholeWeek"
    style="background-color:{{ weekInfo.days[0].backgroundColor }}"
    [class]="{
      week__day: true,
      'week--whole': true,
      'week--hatching': isInstallationSession
          && weekInfo.days[0].dictWorkScheduleStudyProcessType?.CategoryId != holidaysEnum.holiday
          && weekInfo.days[0].dictWorkScheduleStudyProcessType?.CategoryId != holidaysEnum.weekend
    }"
  >
    {{ weekInfo.days[0].symbol }}
  </div>
  <ng-template [ngIf]="weekInfo.days.length !== 1 || !isWholeWeek">
    <div
      *ngFor="let day of days; let idx = index"
      [class]="{
        week__day: true,
        'week--selected': isSelectedCell && idx === currentDay,
        'week__day--hatching': (isInstallationSessionBegin && idx >= weekInfo.installationSessionBeginDayNumber
          || isInstallationSessionEnd && idx <= weekInfo.installationSessionEndDayNumber
          || isInstallationSession && !isInstallationSessionBegin && !isInstallationSessionEnd)
          && day.dictWorkScheduleStudyProcessType?.CategoryId != holidaysEnum.holiday
          && day.dictWorkScheduleStudyProcessType?.CategoryId != holidaysEnum.weekend
      }"
      style="background-color:{{ day.backgroundColor }}"
    >
      {{ day.symbol }}
    </div>
  </ng-template>
  <div
    class="dot"
    *ngIf="isSelectedCell && !isChoosedCells && isDisplayDot"
  ></div>
</div>
