import { Component, Input, OnInit } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent extends BaseFilterCellComponent {

  public get selectedValue(): unknown {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  @Input() public override filter!: CompositeFilterDescriptor;
  @Input() public data!: unknown[];
  @Input() public textField!: string;
  @Input() public valueField!: string;

  public get defaultItem(): { [Key: string]: unknown } {
    return {
      [this.textField]: '',
      [this.valueField]: null
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public onChange(value: unknown): void {
    this.applyFilter(
      value === null ? // value of the default item
        this.removeFilter(this.valueField) : // remove the filter
        this.updateFilter({ // add a filter for the field with the value
          field: this.valueField,
          operator: 'eq',
          value: value
        })
    ); // update the root filter
  }

}
