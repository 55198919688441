<h1>{{isEditingMode ? 'Редактировать место работы' : 'Добавить место работы'}}</h1>

<div class="grid-container">
  <div>
    <form class="k-form" [formGroup]="employmentForm">
      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Наименование организации-работодателя"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-combobox #organizationComboBox
                      [data]="organizations"
                      textField="organizationName"
                      valueField="id"
                      [valuePrimitive]="true"
                      [kendoDropDownFilter]='filterSettings'
                      placeholder="Организация"
                      formControlName="dictOrganizationId"
                      class="input-main-addition-data"
                      (click)="organizationComboBox.toggle(true)"
                      required>
              </kendo-combobox>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Должность"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-combobox #employerComboBox
                              [data]="employerPosts"
                              textField="postName"
                              valueField="id"
                              [valuePrimitive]="true"
                              [kendoDropDownFilter]='filterSettings'
                              placeholder="Должность"
                              formControlName="dictEmployerPostId"
                              class="input-main-addition-data"
                              (click)="employerComboBox.toggle(true)"
                              required>
              </kendo-combobox>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Дата трудоустройства"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-datepicker
                format="dd.MM.yyyy"
                formControlName="dateStart"
                class="input-main-addition-data"
                required>
              </kendo-datepicker>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Дата увольнения"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-datepicker
                format="dd.MM.yyyy"
                formControlName="dateFinish"
                class="input-main-addition-data">
              </kendo-datepicker>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Текущее место работы"></kendo-label>
          <dd>
            <kendo-formfield>
              <input [ngModel]="isCurrent" type="checkbox" kendoCheckBox formControlName="isCurrent"/>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Подтверждающий документ"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-fileselect
                class="input-main-addition-data"
                [restrictions]="restrictions"
                [multiple]="false"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="files">
                <kendo-fileselect-messages
                  invalidFileExtension="Недопустимый формат файла.">
                </kendo-fileselect-messages>
              </kendo-fileselect>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
            <a *ngIf="employmentForm?.value.scanFileFullName && isEditingMode && !files" class="link"
               (click)="downloadFile(employmentForm?.value?.employmentId)">
              {{ employmentForm?.value?.scanFileFullName }}
            </a>
          </dd>
        </div>
      </dl>
    </form>
    <div class="edit-mode-buttons">
      <button kendoButton
              size="small"
              (click)="moveToInfo()"
      >
        Закрыть
      </button>
      <button kendoButton
              class="save-button"
              size="small"
              themeColor="primary"
              (click)="onSave($event)"
      >
        Сохранить
      </button>
    </div>
  </div>
</div>
