import { Component, OnInit } from '@angular/core';
import { CategoryBaseUnit, LabelRotation, SeriesType } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimeTableComponent implements OnInit {

  constructor() { }

  public gridData = [
    { id: 1, subject: "Психологическое сопровождение пациентов в общей медицинской практике", time: "9:00", date: "23.01.2022", location: "400(III)", fio:"Иванов Иван Иванович" },
    { id: 2, subject: "Психология, психопатология и психотерапия зависимостей", time: "9:00", date: "23.01.2022", location: "400(III)", fio:"Иванов Иван Иванович"  },
    { id: 3, subject: "Типология личностных расстройств", date: "23.01.2022", time: "9:00", location: "400(III)", fio:"Иванов Иван Иванович" },
  ];

  public coursesData = [
    {id: 1, name: "1 курс"},
    {id: 2, name: "2 курс"},
    {id: 3, name: "3 курс"},
  ]

  ngOnInit(): void {
  }

}