import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ControlAction, controlActions} from "../../../../../models/mfc/enums/control-action.enum";
import {ApplicationEditFormService} from "../../../../../services/mfc/application-edit-form.service";
import {DisciplineTableService} from "../../../../../services/mfc/discipline-table.service";
import {EnableDisciplineTable} from "../../../../../models/mfc/enums/enable-discipline-table.enum";
import {DisciplineTableFull} from "../../../../../models/mfc/applicationForm/discipline-table.model";
import {ApplicationFormService} from "../../../../../services/mfc/application-form.service";
import {lastValueFrom, Observable} from "rxjs";
import {createMap} from "../../../../../helpers/map-helper";

@Component({
  selector: 'mfc-discipline-table-form',
  templateUrl: './discipline-table-form.component.html',
  styleUrls: ['./discipline-table-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisciplineTableFormComponent implements OnInit {
  private rowId = this.activatedRoute.snapshot.params['disciplineid'];
  private applicationId = this.activatedRoute.snapshot.params['id'];
  private isSemester = false;

  private readonly controlActions = createMap({value: controlActions, valueField: 'id', textField: 'name'});
  protected fields: { name: string, value: string }[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private editFormService: ApplicationEditFormService,
    private disciplineTableService: DisciplineTableService,
    private cdr: ChangeDetectorRef,
    private applicationService: ApplicationFormService,
  ) {}

  async ngOnInit() {
    this.disciplineTableService.applicationId = this.applicationId;
    this.isSemester = await this.checkDiscipline();

    this.fetchRowData(this.rowId);
  }

  private async checkDiscipline() {
    if (!this.editFormService.studentId) {
      const application = await lastValueFrom(this.applicationService.getApplicationById(this.applicationId));
      this.editFormService.enableDisciplineTable = application.enableDisciplineTable;
    }

    return [EnableDisciplineTable.BySemester, EnableDisciplineTable.BySemesterPractice]
      .includes(this.editFormService.enableDisciplineTable ?? EnableDisciplineTable.No);
  }

  private fetchRowData(rowId: string) {
    const serviceMethod = this.isSemester
      ? this.disciplineTableService.getRowBySemester
      : this.disciplineTableService.getRowByCycleType;
    (serviceMethod as (rowId: string) => Observable<DisciplineTableFull>).call(this.disciplineTableService, rowId)
      .subscribe((response) => {
        this.fields = this.mapFields(response);
        this.cdr.detectChanges();
    });
  }

  private mapFields(discipline: DisciplineTableFull) {
    const commonFields = [
      {name: 'Тип', value: discipline.dictDisciplineTypeName ?? ''},
      {name: 'Дисциплина', value: discipline.dictDisciplineName ?? ''},
      {name: 'Дисциплина (печатное название)', value: discipline.printName ?? '-'},
      {
        name: 'Тип промежуточного контроля',
        value: this.controlActions.get(discipline.controlActionEnum ?? ControlAction.NoControlAction)
          ?.toString() ?? ''
      },
      {name: 'Трудоемкость (общее количество часов), ч.', value: discipline.hours?.toString() ?? ''},
      {name: 'Трудоемкость, з.ед.', value: discipline.laborIntensity?.toString() ?? ''},
    ];

    const semesterFields = [
      {name: 'Семестр', value: discipline.semester?.toString() ?? ''},
    ];

    const cycleTypeFields = [
      {name: 'Факультатив', value: discipline.faculty ? 'Да' : 'Нет'},
      {name: 'Курсовая работа', value: discipline.courseWorkOrCourseProject ? 'Да' : 'Нет'},
      {name: 'Трудоемкость (количество аудиторных часов), ч.', value: discipline.hoursContactWork?.toString() ?? ''},
    ];

    return [...commonFields, ...(this.isSemester ? semesterFields : cycleTypeFields)];
  }

  protected onCancel() {
    this.editFormService.currentTab = parseInt(localStorage.getItem('mfcDisciplineIndex') ?? '0');
    this.router.navigate([`mfc/applicationForm/${this.applicationId}`]);
  }

  protected trackByFn(index: number, item: { name: string, value: string }) {
    return item.name;
  }
}