import {ApplicationStatusesEnum} from '../enums/application-statuses.enum';

export interface ApplicationStatus {
  id: string;
  name: string;
  applicationStatusEnum: ApplicationStatusesEnum;
  sortNumber?: number;
}

export const editableStatuses: ApplicationStatusesEnum[] = [
  ApplicationStatusesEnum.Draft,
  ApplicationStatusesEnum.Revision,
  ApplicationStatusesEnum.Withdrawn
];

export const displayFIOStatuses: ApplicationStatusesEnum[] = [
  ApplicationStatusesEnum.Sent,
  ApplicationStatusesEnum.Revision,
  ApplicationStatusesEnum.Rejected,
];

export const statusActions = new Map<ApplicationStatusesEnum, string>([
  [ApplicationStatusesEnum.Sent, 'Send'],
  [ApplicationStatusesEnum.Withdrawn, 'ToWithdrawn'],
]);
