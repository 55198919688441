import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKStudentAcademicState } from 'src/app/models/profile/lkStudentAcademicState.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKStudentAcademicStateService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.StudentAcademicState}`;

  constructor(private http: HttpClient) { }

  //Get StudentAcademicState
  public getStudentAcademicStates(studentId: string): Observable<LKStudentAcademicState> {
    return this.http.get<LKStudentAcademicState>(this.baseUrl + '/GetStudentAcademicStates/' + studentId)
      .pipe(
        catchError(this.handleError));
  }


  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
