import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs";
import {handleError} from "../../../helpers/errorHandle-helper";
import {StudentSemester} from "../../../models/mfc/student-semester.model";

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  private readonly baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.student}`;

  constructor(private http: HttpClient,) { }

  getSemesters(studentId: string) {
    return this.http.get<StudentSemester[]>(`${this.baseUrl}/GetSemesters`, {params: {studentId}})
      .pipe(catchError(handleError));
  }
}