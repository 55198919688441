import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss']
})
export class ExamsComponent {

  @Input() public exam!: {
    id: number;
    subject: string;
    date: string;
    location: string;
  };

}
