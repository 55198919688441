import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Comments } from '../../models/announcement/comments.model';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })

  export class CommentsService {

  baseUrl = environment.announcementApiEndpoint + 'announcement';
  //baseUrl = 'https://test-lks-api.uni-systems.ru/announcement';


  constructor(private http: HttpClient) { }

  public getComments(message_id: number) {
   
    return this.http.get<Comments[]>(this.baseUrl + '/message/' + message_id + '/comments' )
      .pipe(
        catchError(this.handleError));
  }

  public deleteComments(id: number){
    return this.http.delete(this.baseUrl + '/comments/' + id, {responseType: 'text' as 'text'});
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}