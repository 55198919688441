import {EventEmitter, Injectable, Output} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Phone} from "../../models/profile/contacts/phones.model";

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.phone}`;

  constructor(private http: HttpClient) { }

  progress?: number;
  message?: string;
  @Output() public onUploadFinished = new EventEmitter();

  public getAllSocialNetwork(): Observable<Phone[]> {
    return this.http.get<Phone[]>(this.baseUrl);
  }

  public getPhoneById(studentId: string, id: string): Observable<Phone> {
    return this.http.get<Phone>(`${this.baseUrl}/${studentId}/${id}`);
  }

  public updatePhone(studentId: string, id: string, phone: Phone): Observable<Phone> {
    return this.http.put<Phone>(`${this.baseUrl}/${studentId}/${id}`, phone);
  }

  public addPhone(studentId: string, phone: Phone): Observable<Phone> {
    return this.http.post<Phone>(`${this.baseUrl}/${studentId}`, phone);
  }

  public deletePhone(studentId: string, phoneId: string) {
    return this.http.delete(`${this.baseUrl}/${studentId}/${phoneId}`);
  }
}

