import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {EmailNotification} from "../../../models/home/emailnotification.model";

@Injectable({
  providedIn: 'root'
})
export class EmailNotificationService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.emailnotification}`;

  constructor(private http: HttpClient) { }

  public GetEmailNotification(): Observable<EmailNotification> {
    return this.http.get<EmailNotification>(this.baseUrl + '/GetEmailNotification/');
  }

  public EditEmailNotification(request: EmailNotification) {
    return this.http.put(this.baseUrl + '/EditEmailNotification/', request)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
