export enum DictPropertyEnum {
  // Предполагаемый профили трудовой деятельности
  WorkProfile = 1,
  // Планируете ли вы обучение в ординатуре/аспирантуре?
  PlansForResidencyOrGraduateStudy,
  // Статьи ТК РФ для справки-вызов
  Articles,
  // Дата
  Date,
  // Номер
  Number,
  // Места для предоставления справки
  PresentationPlace,
  // Фамилия в родительном падеже
  RLastName,
  // Имя в родительном падеже
  RFirstName,
  // Отчество в родительном падеже
  RMiddleName,
  // Обучающиеся
  StudentTable,
  // Затраты
  CostTable,
  // Документ о смене ФИО
  ChangeNameDocument,
  // Продолжительность
  Duration,
  // Причина отчисления
  DeductionReason,
  // Наименование организации, в которой планируете работать
  Organization,
  // Предполагаемая должность
  ProposedPost,
  // Выберите наименование специальности, по которой Вы планируете обучаться
  Specialization,
}
