export enum DictMarkValue {
  // Прослушано
  None = 0,
  // Удовлетворительно
  Satisfactory = 3,
  // Хорошо
  Good = 4,
  // Отлично
  Great = 5,
  // Зачтено
  Passed = 6
}