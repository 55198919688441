import {EventEmitter, Injectable, Output} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SocialNetwork} from "../../models/profile/contacts/socialnetworks.model";

@Injectable({
  providedIn: 'root'
})
export class SocialNetworkService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.socialNetwork}`;

  constructor(private http: HttpClient) { }

  progress?: number;
  message?: string;
  @Output() public onUploadFinished = new EventEmitter();

  public getAllSocialNetwork(): Observable<SocialNetwork[]> {
    return this.http.get<SocialNetwork[]>(this.baseUrl);
  }

  public getSocialNetworkById(studentId: string, id: string): Observable<SocialNetwork> {
    return this.http.get<SocialNetwork>(`${this.baseUrl}/${studentId}/${id}`);
  }

  public addSocialNetwork(studentId: string, socialNetwork: SocialNetwork): Observable<SocialNetwork> {
    return this.http.post<SocialNetwork>(`${this.baseUrl}/${studentId}`, socialNetwork);
  }

  public updateSocialNetwork(studentId: string, socialNetworkId: string, socialNetwork: SocialNetwork): Observable<SocialNetwork> {
    return this.http.put<SocialNetwork>(`${this.baseUrl}/${studentId}/${socialNetworkId}`, socialNetwork);
  }

  public deleteSocialNetwork(studentId: string, socialId: string) {
    return this.http.delete(`${this.baseUrl}/${studentId}/${socialId}`);
  }
}

