import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-electro-resources',
    templateUrl: './electro-resources.component.html',
    styleUrls: ['./electro-resources.component.scss'],
    providers: []
})

export class ElectroResourcesComponent implements OnInit {

constructor(){}

ngOnInit(): void {}

}