<h1>{{isEditingMode ? 'Редактировать контакт' : 'Добавить новый контакт'}}</h1>

<div class="grid-container">
  <div class="container-form">
    <div class="content-row">
      <kendo-label text="Тип контакта"></kendo-label>
      <dd>
        <div class="row">
          <kendo-dropdownlist
            (valueChange)="onContactTypeChange($event)"
            [data]="dictContactTypes"
            [valuePrimitive]="true"
            required
            textField="dictContactTypeName"
            valueField="type"
            [value]="contactType"
            [disabled]="isEditingMode"
          >
          </kendo-dropdownlist>
          <span class="required">*</span>
        </div>
      </dd>
    </div>

    <!--Почта-->
    <form *ngIf="emailMode" class="contactContainer" [formGroup]="emailForm">
      <div class="content-row">
        <kendo-label text="Тип почты"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist
              [data]="emailTypes"
              [valuePrimitive]="true"
              formControlName="dictEmailTypeId"
              textField="emailTypeName"
              valueField="id"
              required
            >
            </kendo-dropdownlist>
            <span class="required">*</span>
          </kendo-formfield>
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Почта"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              [clearButton]="true"
              formControlName="email"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              placeholder="E-mail"
              size="small"
              required
              (valueChange)="setDefaultMainOptions()"
            ></kendo-textbox>
            <span class="required">*</span>
          </kendo-formfield>
          <kendo-formerror *ngIf="emailForm.controls?.['email']?.errors as errors">
            <ng-container *ngIf="errors['pattern']">Ошибка: Некорректный формат e-mail.</ng-container>
          </kendo-formerror>
        </dd>
      </div>

      <div class="content-row" *ngIf="!systemSettingIsHidden(systemEnum.student_can_edit_deanery_contacts)">
        <kendo-label text="Использовать как основную"></kendo-label>
        <dd class="content-row-column">
          <kendo-formfield>
            <input type="checkbox" [(ngModel)]="isMain" [attr.disabled]="currentActive === true ? true : null" kendoCheckBox formControlName="isMain" />
          </kendo-formfield>
          <button class="btn-action"
                  *ngIf="isMain && !isCodeValid && !isCodeSent"
                  (click)="sendCodeToEmail()"
                  [disabled]="isWaitingServerResponse"
          >
            Подтвердить почту
          </button>
          <p class="confirm-notification" *ngIf="isCodeSent && !isCodeValid">
            Мы отправили письмо с кодом на введенную электронную почту. Введите код подтверждения в поле ниже и нажмите кнопку “Подтвердить”.
          </p>
          <p class="confirm-access" *ngIf="isMain && isCodeValid">
            Почта подтверждена
          </p>
        </dd>
        <kendo-icon kendoTooltip
                    *ngIf="isMain"
                    [tooltipWidth]="360"
                    [title]="tooltip"
                    name="info"
                    tooltipClass="tooltip"
                    position="right"
                    size="medium">
        </kendo-icon>
      </div>

      <div *ngIf="isMain && isCodeSent && !isCodeValid" class="content-row">
        <kendo-label text="Код"></kendo-label>
        <dd>
          <div class="content-row-container">
            <kendo-formfield>
              <kendo-textbox
                class="mw-300"
                formControlName="code"
                format="n0">
              </kendo-textbox>
            </kendo-formfield>
            <button class="btn-confirm" (click)="checkValidCode()" [disabled]="isWaitingServerResponse" *ngIf="isMain">Подтвердить</button>
          </div>
          <button class="btn-action"
                  (click)="sendCodeToEmail()"
                  [disabled]="isWaitingServerResponse"
          >
            Выслать подтверждение еще раз
          </button>
        </dd>
      </div>

    </form>

    <form *ngIf="socialMode" class="contactContainer" [formGroup]="socialForm">

      <div class="content-row">
        <kendo-label text="Название соц. сети"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist
              [data]="socialNetworksTypes"
              [valuePrimitive]="true"
              formControlName="dictSocialNetworkId"
              textField="socialNetworkName"
              valueField="dictSocialNetworkId"
              required
            >
            </kendo-dropdownlist>
            <span class="required">*</span>
          </kendo-formfield>
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Ссылка на соц. сеть"></kendo-label>
        <dd>
          <kendo-formfield class="containerFormLine">
            <div class="contentLine">
              <kendo-textbox
                [clearButton]="true"
                formControlName="socialNetwork"
                pattern="(https?://)([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*/?"
                placeholder="Соц. сеть"
                required
              ></kendo-textbox>
              <span class="required">*</span>
            </div>
            <kendo-formerror *ngIf="socialForm.controls?.['socialNetwork']?.errors">Ошибка: Некорректный формат ссылки на соц. сеть.</kendo-formerror>
          </kendo-formfield>
        </dd>
      </div>

    </form>

    <form *ngIf="phoneMode" class="contactContainer" [formGroup]="phoneForm">

      <div class="content-row">
        <kendo-label text="Тип номера"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist
              [data]="phoneTypes"
              [valuePrimitive]="true"
              formControlName="dictPhoneTypeId"
              required
              textField="phoneTypeName"
              valueField="id"
            >
            </kendo-dropdownlist>
            <span class="required">*</span>
          </kendo-formfield>
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Номер телефона"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-maskedtextbox
              [mask]="phoneNumberMask"
              formControlName="phoneNumber"
              placeholder="Мобильный телефон"
              required
              size="small"
            ></kendo-maskedtextbox>
            <span class="required">*</span>
          </kendo-formfield>
          <kendo-formerror *ngIf="phoneForm.controls?.['phoneNumber']?.errors as errors">
            <ng-container *ngIf="errors['pattern']">Ошибка: Некорректный формат номера телефона.</ng-container>
          </kendo-formerror>
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Использовать как основной"></kendo-label>
        <dd class="content-row-column">
          <kendo-formfield>
            <input type="checkbox" [(ngModel)]="isMain" [attr.disabled]="currentActive === true ? true : null" kendoCheckBox formControlName="isMain" />
          </kendo-formfield>
        </dd>
      </div>

    </form>

    <form *ngIf="phoneCityMode" class="contactContainer" [formGroup]="phoneCityForm">

      <div class="content-row">
        <kendo-label text="Номер телефона"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              [clearButton]="true"
              formControlName="phoneNumber"
              pattern="[0-9 +()-]*"
              placeholder="Городской телефон"
              required
            ></kendo-textbox>
            <span class="required">*</span>
          </kendo-formfield>
          <kendo-formerror *ngIf="phoneCityForm.controls?.['phoneNumber']?.errors as errors">
            <ng-container *ngIf="errors['pattern']">Ошибка: Некорректный формат номера телефона.</ng-container>
          </kendo-formerror>
        </dd>
      </div>

    </form>

    <div class="buttons">
      <button kendoButton
              size="small"
              (click)="toProfile()"
      >
        Отмена
      </button>
      <button class="save-button"
              kendoButton
              size="small"
              themeColor="primary"
              (click)="onSaveContact($event)"
              [disabled]="isFormInvalid() || isWaitingServerResponse"
      >
        Сохранить
      </button>
      <button kendoButton
              size="small"
              (click)="onDeleteContact()"
              *ngIf="canBeDeleted()"
      >
        Удалить
      </button>
    </div>

  </div>
</div>
