import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent  {

  @Input() public event!: {
    id: number;
    title: string;
    author: string;
    date: string;
    annotation: string;
  };

}
