import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {handleError} from '../../helpers/errorHandle-helper';
import {catchError, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Property} from '../../models/mfc/applicationConstructor/tabs-settings.model';
import {ApplicationStatusesEnum} from '../../models/mfc/enums/application-statuses.enum';
import {
  ApplicationForm,
  ApplicationNumber,
  GetApplication, RelatedApplication
} from '../../models/mfc/applicationForm/application-form.model';
import {statusActions} from '../../models/mfc/dicts/application-status.model';
import {PropertiesService} from './properties.service';
import {NotificationsService} from '../Notifications/notifications.service';


@Injectable({
  providedIn: 'root'
})
export class ApplicationFormService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.application}/`;

  constructor(
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private propertiesService: PropertiesService
  ) { }

  //TODO: временно
  private mapData(data: any): GetApplication {
    const tabs = data.application.tabs;
    const relatedApplications = this.mapApplicationNumbers(data.application.relatedApplications ?? []);
    tabs.forEach((tab: any) => tab.groupProperties.forEach((group: any) => {
      group.standardProperties = group.standardProperties.map((property: any) => this.mapProperty(property));
      group.additionalProperties = group.additionalProperties.map((property: any) => this.mapProperty(property));
    }));
    return {...data.application, tabs: tabs, relatedApplications: relatedApplications};
  }

  private mapProperty(property: any): Property {
    const dict = property.dict?.dictValues;
    const prop = property;
    if (dict) prop.dict = dict;
    return prop;
  }

  private mapApplicationNumbers(data: ApplicationNumber[]|RelatedApplication[]) {
    const result = data;
    data.forEach((item) => item.applicationNumber = `Заявка ${item.applicationNumber}`);
    return result as ApplicationNumber[] & RelatedApplication[];
  }

  private createApplication(application: ApplicationForm) {
    return this.http.post<string>(this.baseUrl, this.propertiesService.getFormData(application))
      .pipe(catchError(handleError));
  }

  private updateApplication(applicationId: string, application: ApplicationForm) {
    return this.http.put(`${this.baseUrl}${applicationId}`, this.propertiesService.getFormData(application))
      .pipe(catchError(handleError));
  }

  public getApplicationById(applicationId: string): Observable<GetApplication> {
    return this.http.get<GetApplication>(`${this.baseUrl}${applicationId}`)
      .pipe(map((d) => this.mapData(d)), catchError(handleError));
  }

  public getApplicationNumbers(): Observable<ApplicationNumber[]> {
    return this.http.get<ApplicationNumber[]>(`${this.baseUrl}GetStudentApplicationNumberList/`)
      .pipe(map((d) => this.mapApplicationNumbers(d ?? [])), catchError(handleError));
  }

  public saveApplication(application: ApplicationForm, applicationId?: string) {
    if (applicationId) {
      return this.updateApplication(applicationId, application);
    } else {
      return this.createApplication(application);
    }
  }

  public deleteApplication(applicationId: string) {
    return this.http.delete(`${this.baseUrl}${applicationId}`)
      .pipe(catchError(handleError));
  }

  public changeStatus(id: string, status: ApplicationStatusesEnum) {
    return this.http.put(`${this.baseUrl}${id}/${statusActions.get(status)}`, {})
      .pipe(catchError(handleError));
  }

  public downloadDocumentSign(applicationId: string, documentId: string) {
    return this.http.get(`${this.baseUrl}${applicationId}/document/${documentId}/sign`, {responseType: 'blob'})
      .pipe(
        catchError((err) => {
          this.notificationsService.showError(err.error.Message ?? err.error);
          return handleError(err);
        }));
  }

  public downloadPropertyFile(documentId: string) {
    return this.http.get(`${this.baseUrl}DownloadPropertyFile/${documentId}`, {responseType: 'blob'})
      .pipe(
        catchError((err) => {
          this.notificationsService.showError(err.error.Message ?? 'Не удалось скачать файл');
          return handleError(err);
        }));
  }
}
