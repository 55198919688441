export class TableHeaderType {
  public days?: string;
  public weeks?: TableHeaderWeekType[];
  public month?: string;
  public title?: string;
}
export class TableHeaderWeekType {
  public days?: string;
  public title?: string;
}
export class ResponseScheduleProcess {
  public courses!: ScheduleCourseData[];
  public dates!: ScheduleDate[];
}

export class ScheduleCourseData {
  public courseNumber!: number;
  public semesters!: SemestrSettings[];
  public workTypes!: WorkType[];
}

export class SemestrSettings {
  public semesterNumber!: number;
  public semesterBegin!: string | null;
  public semesterBeginWeekNumber!: number | null;
  public installationSessionBegin!: string | null;
  public installationSessionEnd!: string | null;
}
export class WorkType {
  DaysAmount!: number;
  SerialNumberWeek!: number;
  WeekNumber!: number;
  SemesterNumber!: number;
  WorkType!: DictWorkScheduleStudyProcessType;
}

export class DictWorkScheduleStudyProcessType {
  Id?: string;
  Symbol!: string;
  Color!: string;
  CategoryId?: string;
}

export class ScheduleDate {
  start!: string;
  end!: string;
  weekNumber!: number;
  title!: string;
}

export class TableDataType {
  courseNumber!: number;
  weeksNumber!: CellDataType[];
}

export class CellDataType {
  public days!: CellDayType[];
  public semestrBeginWeekNumber?: number[];
  public weekNumber!: number;
  public semesterNumber?: number;
  public installationSessionBeginWeekNumber?: number;
  public installationSessionEndWeekNumber?: number;
  public installationSessionBeginDayNumber!: number;
  public installationSessionEndDayNumber!: number;
  public isInstallationSession?: boolean;
}

export class CellDayType {
  public daysAmount!: number;
  public symbol!: string;
  public backgroundColor!: string;
  public dictWorkScheduleStudyProcessType?: DictWorkScheduleStudyProcessType;
  public semesterNumber!: number;
}
