<div class="range">
  <label class="range-label">
    <kendo-datepicker [ngClass]="{error: editable && !startDate && (required || endDate)}"
                      #dateStart
                      [openOnFocus]="dateStart"
                      [value]="startDate!"
                      [max]="max!"
                      (valueChange)="onStartChange($event)"
                      [disabled]="!editable"
                      placeholder="с">
    </kendo-datepicker>
  </label>
  <label class="range-label">
    <kendo-label class="hyphen">-</kendo-label>
    <kendo-datepicker [ngClass]="{error: editable && !endDate && (required || startDate) || (startDate! > endDate!)}"
                      #dateEnd
                      [openOnFocus]="dateEnd"
                      [value]="endDate!"
                      [min]="min!"
                      (valueChange)="onEndChange($event)"
                      [disabled]="!editable"
                      placeholder="по">
    </kendo-datepicker>
  </label>
</div>
