export enum ControlAction {
  // Без контрольной акции
  NoControlAction = 1,
  // Зачет
  Credit,
  // Зачет с оценкой
  CreditWithMark,
  // Экзамен
  Exam
}

export const controlActions = [
  {id: ControlAction.NoControlAction, name: 'Без контрольной акции'},
  {id: ControlAction.Credit, name: 'Зачет'},
  {id: ControlAction.CreditWithMark, name: 'Зачет с оценкой'},
  {id: ControlAction.Exam, name: 'Экзамен'}
]