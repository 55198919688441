import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {RemoveEvent} from "@progress/kendo-angular-grid";
import {CostTableRow} from "../../../../../../models/mfc/applicationForm/tables.model";

@Component({
  selector: 'mfc-cost-table',
  templateUrl: './cost-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CostTableComponent implements OnInit {
  @Input() editable = false;
  @Input() data: CostTableRow[] = [];

  @Output() value = new EventEmitter<CostTableRow[]>();

  protected gridView: CostTableRow[] = [];

  ngOnInit(): void {
    if (!this.data.length) {
      this.addRow();
    }
    this.gridView = this.data;
  }

  protected addRow() {
    this.data.push(new CostTableRow());
    this.onValueChange();
  }

  protected removeRow({rowIndex}: RemoveEvent) {
    this.data.splice(rowIndex, 1);
    this.onValueChange();
  }

  protected onValueChange() {
    this.value.emit(this.data);
  }
}