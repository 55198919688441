import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {
  AddElectiveDisciplineStudentCommand, DeleteElectiveDisciplineStudentCommand, SignStatementCommand,
  StudentElectiveDiscipline
} from "../../models/iot/student-elective-discipline.model";
import {catchError} from "rxjs";
import {handleError} from "../../helpers/errorHandle-helper";
import {StudentStudyYear} from "../../models/iot/student-study-year.model";

@Injectable({
  providedIn: 'root'
})

export class IotService {
  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.iot}`;

  constructor(private http: HttpClient) { }

  public getStudentElectiveDisciplines(studyYear: number) {
    return this.http.post<StudentElectiveDiscipline[]>(`${this.baseUrl}/GetStudentElectiveDisciplines/`, {studyYear: studyYear})
      .pipe(catchError(handleError));
  }

  public getStudentStudyYears() {
    return this.http.get<StudentStudyYear[]>(`${this.baseUrl}/GetStudentStudyYears`)
      .pipe(catchError(handleError));
  }

  public addStudentElectiveDiscipline(command: AddElectiveDisciplineStudentCommand) {
    return this.http.post(`${this.baseUrl}/AddStudentElectiveDiscipline/`, command);
  }

  public deleteStudentElectiveDiscipline(command: DeleteElectiveDisciplineStudentCommand) {
    return this.http.delete(`${this.baseUrl}/DeleteStudentElectiveDiscipline/`, { body: command });
  }

  public signStatement(command: SignStatementCommand) {
    return this.http.post(`${this.baseUrl}/SignStatement/`, command);
  }
}
