import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { keyBy, orderBy } from 'lodash';
import { DictQualification } from 'src/app/models/education/DictQualification.model';
import { EducationStandard } from 'src/app/models/education/EducationStandard.model';
import { LKEducationBudgetTime } from 'src/app/models/education/LKEducationBudgetTime.model';
import { LKEducationInternsityData } from 'src/app/models/education/LKEducationIntensityData.model';
import {
  LKEducationPlan,
  EducationPlanDiscipline,
  EducationPlanControllingAction,
  EducationPlanSemesters,
  EducationPlanTypeWorks,
  EducationPlanAdditionalWorks
} from 'src/app/models/education/LKEducationPlan.model';
import { LKEducationProgram } from 'src/app/models/education/LKEducationProgram.model';
import { LKStudPlan } from 'src/app/models/education/LKStudPlan.model';
import { DictStudyForm } from 'src/app/models/profile/dictionaries/DictStudyForm.model';
import { Department } from 'src/app/models/profile/external/department.model';
import { Faculty } from 'src/app/models/profile/external/faculty.model';
import { LKEduGroup } from 'src/app/models/profile/lkEduGroup.model';
import { LKStudEduGroup } from 'src/app/models/profile/lkStudEduGroup.model';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { LKEducationPlanService } from 'src/app/services/LKEducation/LKEducationBUP/lkeducationPlan.service';
import { LKStudEduGroupService } from 'src/app/services/LKStudent/lkstud-edu-group.service';
import { LKStudPlanService } from 'src/app/services/LKStudent/lkstud-plan.service';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';

@Component({
  selector: 'app-education-discipline',
  templateUrl: './education-discipline.component.html',
  styleUrls: ['./education-discipline.component.scss']
})
export class EducationDisciplineComponent implements OnInit {

  public rassredPracticeName = 'Распределенная практика';
  public contactWorkName = 'Контактная работа';
  public controlActionsData: string[] = [];
  public totalArr: any[] = [];
  private maxColumnNumber = 0;
  public contactSum = 0;
  public selfWorkSum = 0;
  public controlSum = 0;
  public hoursCreditedSum = 0;

  // Название дисциплины
  public disciplineName? = "-";
  public departmentName? = "-";
  private idNumber = String(this.route.snapshot.paramMap.get('id'));

  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public studyForm: DictStudyForm = {
    studyFormName: '',
    studyFormSName: ''
  }

  public educationStandard: EducationStandard = {
    cipher: '',
    name: '',
    shortName: '',
    dictStandardTypeId: '',
    dictEducationLevelId: '',
    dictTrainingLevelId: ''
  }

  public faculties: Faculty[] = [];
  public faculty: Faculty = {
    name: '-',
    shortName: '-'
  }

  public department: Department = {
    name: '-',
    shortName: '-',
    faculty: this.faculty
  }

  public dictQualification: DictQualification = {
    qualificationName: '',
    qualificationSName: ''
  }

  public studGroupEducationProgram: LKEducationProgram = {
    lkEducationProgramId: 0,
    lkEducationProgramExternalId: '',
    cipher: '',
    name: '',
    shortname: '',
    educationStandardId: '',
    educationStandard: this.educationStandard,
    dictQualificationId: '',
    dictQualification: this.dictQualification,
    departmentId: '',
    graduatingDepartment: this.department,
    faculty: this.faculty
  }

  public educationPrograms: LKEducationProgram[] = [];
  public educationProgram: LKEducationProgram = {
    lkEducationProgramId: 0,
    lkEducationProgramExternalId: '',
    cipher: '',
    name: '',
    shortname: '',
    educationStandardId: '',
    educationStandard: this.educationStandard,
    dictQualificationId: '',
    dictQualification: this.dictQualification,
    departmentId: '',
    graduatingDepartment: this.department,
    faculty: this.faculty
  }

  public educationPlans: LKEducationPlan[] = [];
  public educationPlan: LKEducationPlan = {
    educationPlanId: 0,
    educationPlanExternalId: "",
    jsonData: "",
    educationProgramId: '',
    educationProgram: this.educationProgram,
    dictStudyFormId: '',
    dictStudyForm: this.studyForm
  }

  public eduGroup?: LKEduGroup = {
    courseNum: 0,
    groupName: "-",
    faculty: this.faculty,
    facultyId: ""
  }

  public studEduGroup?: LKStudEduGroup = {
    studPersonId: '',
    studentId: '',
    fullName: '',
    eduGroupId: '',
    eduGroup: this.eduGroup
  }

  public studPlan?: LKStudPlan = {
    studentId: '',
    educationPlan: this.educationPlan,
    department: this.department
  }

  //public disciplineData: EducationPlanDiscipline[] = [];
  public controllingActions: EducationPlanControllingAction[] = [];
  public semesters: EducationPlanSemesters[] = [];
  public controlActionUnique: {name: string;}[] = [];
  public semestersUnique: {name: string;}[] = [];
  public typeWorks: EducationPlanTypeWorks[] = [];
  public additionalWorks: EducationPlanAdditionalWorks[] = [];
  public labourHours: any[] = [];
  public controlActionsDistribution: string[] = [];
  activeSemesters: any [] = [];

  constructor(private router: Router,
    private educationPlanService: LKEducationPlanService,
    private studPlanService: LKStudPlanService,
    private studEduGroupService: LKStudEduGroupService,
    private studentService: LKStudentService,
    private route: ActivatedRoute)
  { }

  ngOnInit(): void {
    this.getCurrentStudent();
  }

  returnToEducation() {
    this.router.navigate(['education']);
  }

  //Getting student by login
  public getCurrentStudent() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    this.studentService.getCurrentStudent()
      .subscribe(
        response => {
          this.students = response;
          if (changeStudent) {
            this.studentModel = this.students[changeStudent - 1];
          } else {
            this.studentModel = this.students[0];
          }
          this.getStudEduGroup(`${this.studentModel.externalId}`);
          this.getStudPlan(`${this.studentModel.externalId}`);
        }
      );
  }

  public getStudEduGroup(studentId: string) {
    this.studEduGroupService.getStudEduGroup(studentId)
    .subscribe(
      response => {
        this.studEduGroup = response;
      }
    );
  }

  additionalHours(workTypeName: string, workAdditionalName: string) {
    let work = this.additionalWorks.filter(x => x.Name == workAdditionalName);
    if(work.length>0)
    {
      if(workTypeName == this.contactWorkName)
        return work[0].TypeWorks
          .filter(x => x.ClassroomWork && x.Calc)
          .reduce((sum, current:EducationPlanTypeWorks) => sum + current.Value, 0);

      let typeWork = work[0].TypeWorks.filter(x=>x.Name==workTypeName);
      return typeWork.length > 0 && typeWork[0].Value > 0 ? typeWork[0].Value : null;
    }
    return null;
  }

  intensityCount(dataItem: any, semester: any) {
    const name = dataItem.Name;
    let counter = 0;
    let contactWorkHours = 0;
    let selfWorkHours = 0;
    let hoursCredited = 0;
    let controlHours = 0;
    // Вывод контактной работы, самостоятельной работы, перезачтенных часов
    for (var g=0; g < this.controllingActions.length; g++)
    {
      if(this.controllingActions[g].SemesterNumber == semester.number) {
          switch(name) {
            case this.contactWorkName:
              if(this.controllingActions[g].HoursContactWork != undefined)
              {
                contactWorkHours += this.controllingActions[g].HoursContactWork;
              }
              return contactWorkHours > 0 ? contactWorkHours : null;
            case 'Самостоятельная работа':
              if(this.controllingActions[g].HoursSelfWork != undefined)
              {
                selfWorkHours += this.controllingActions[g].HoursSelfWork;
              }
              return selfWorkHours > 0 ? selfWorkHours : null;
            case 'Перезачтенные часы':
              hoursCredited += this.controllingActions[g].HoursCredited;
              return hoursCredited > 0 ? hoursCredited : null;
            case 'Контроль':
              controlHours += this.controllingActions[g].Control;
              return controlHours > 0 ? controlHours : null;
          }
      }
    }
    // Вывод других данных из TypeWorks
    for (var j=0; j < this.activeSemesters.length; j++)
    {
      for (var i=0; i < this.controllingActions.length; i++)
      {
        for(var t=0; t < this.controllingActions[i].TypeWorks.length; t++)
        {
          if(this.controllingActions[i].TypeWorks[t].Name === name && this.controllingActions[i].SemesterNumber == semester.number)
          {
            counter = this.controllingActions[i].TypeWorks[t].Value
            return counter > 0 ? counter : null;
          }
        }
      }
    }
    return null;
  }

  getActiveSemesters() {
    const activeSemesters: any = []
    for (var i = 0; i < this.semesters.length; i++) {
      for (var t = 0; t < this.controllingActions.length; t++)
      {
        if (this.controllingActions[t].SemesterNumber === this.semesters[i].Number) {
          activeSemesters.push({
            number: 0 + this.controllingActions[t].SemesterNumber,
          })
        }
      }
    }
    this.activeSemesters = orderBy(activeSemesters, 'name')
  }

  findValue(action: EducationPlanControllingAction, workName: any) {
    const icon = '<span class="k-icon k-i-check"></span>';
    if(workName==this.rassredPracticeName){
      return action.IsDistributed ? icon : "";
    }
    if(action.DictControlActionName == workName)
      return icon;
    let work = action.TypeWorks.filter(item => item.Name == workName);
    return (work.length > 0 && work[0].Value > 0) ? work[0].Value : "";
  }

  totalCount(dataItem: any) {
    let sum = 0;
    for (var i=0; i < this.controllingActions.length; i++)
    {
      for(var t=0; t < this.controllingActions[i].TypeWorks.length; t++)
      {
        if(this.controllingActions[i].TypeWorks[t].Name == dataItem.Name)
        {
          sum += this.controllingActions[i].TypeWorks[t].Value
        }
      }
    }
    //вытащим все доп. работы для расчета, из них вытащим всю контактную работу для расчета
    sum += this.additionalWorks.filter(x => x.Calc).map(x => x.TypeWorks
      .filter(x => x.ClassroomWork && x.Calc)
      .reduce((res, current:EducationPlanTypeWorks) => res + current.Value, 0))
      .reduce((res, current:number) => res + current, 0);
    if (sum == 0) {
      return null
    }
    return sum ;
  }

  public getStudPlan(studentId: string) {
    this.studPlanService.getStudPlan(studentId)
    .subscribe(
      async response => {
        this.studPlan = response;
        await delay(500);

        // Вывод базового учебного плана в таблицу
        const arr = (this.studPlan?.educationPlan?.jsonData != null || this.studPlan?.educationPlan?.jsonData != undefined)
          ? JSON.parse(this.studPlan?.educationPlan?.jsonData)
          : JSON.parse(this.studEduGroup!.eduGroup!.educationPlan!.jsonData);

        const disciplines = arr.Disciplines;
        const filtered = disciplines.filter((item: { Id: string; }) => item.Id === this.idNumber);
        // Вывод названия дисциплины
        this.disciplineName = (filtered[0].Cipher != '' ? filtered[0].Cipher + '. ' : '') + filtered[0].DictDisciplineName;
        this.departmentName = filtered[0].DepartmentName;
        this.controllingActions = filtered[0].ControllingActions;
        this.typeWorks = this.controllingActions[0].TypeWorks;
        this.typeWorks = this.controllingActions[0].TypeWorks;

        let dictControlWorks = this.typeWorks.filter(item => !item.Calc && !item.ClassroomWork).map(item => item.Name);
        this.controlActionsData = [
          ...new Set(this.controllingActions.map(item => item.DictControlActionName)),
          ...dictControlWorks
        ];
        if(this.controllingActions.filter(item => item.IsDistributed).length > 0)
          this.controlActionsData.push(this.rassredPracticeName);

        this.labourHours = [
          { Name: this.contactWorkName, Value: 0 },
          ...this.typeWorks.filter(item => item.ClassroomWork), //детализация аудиторной работы
          { Name: 'Самостоятельная работа', Value: 0 },
          { Name: 'Контроль', Value: 0 }
        ]
        if(this.controllingActions.filter(item => item.HoursCredited > 0).length > 0)
          this.labourHours.splice(this.labourHours.length - 1, 0,
          { Name: 'Перезачтенные часы', Value: 0 }
          );

        this.additionalWorks = filtered[0].AdditionalWorks;

        // Parse semesters
        this.semesters = arr.Semesters;
        this.semesters.sort((a, b) => { return a.Number - b.Number; });

        // Убираем дубликаты
        let controlActionNameArr = [];
        let semesterArr = [];
        for (var t = 0; t < this.controllingActions.length; t++)
        {
          controlActionNameArr.push(this.controllingActions[t].DictControlActionName);
          semesterArr.push(this.controllingActions[t].SemesterNumber)
        }
        let controlActionUniqueChars = [...new Set(controlActionNameArr)];
        let semesterUniqueChars = [...new Set(semesterArr)];
        const controlActionIterator = controlActionUniqueChars.values();
        const semesterIterator = semesterUniqueChars.values();
        for (const controlActionLetter of controlActionIterator) {
          this.controlActionUnique.push({
            name: `${controlActionLetter}`,
          })
        }
        for (const letter of semesterIterator) {
          this.semestersUnique.push({
            name: `${letter}`,
          })
        }

        this.getActiveSemesters();
        /////// Вывод итоговой суммы в таблицу ///////////
        for (let g=0; g < this.controllingActions.length; g++) {
          this.controlSum += this.controllingActions[g].Control;
          this.contactSum += this.controllingActions[g].HoursContactWork;
          this.hoursCreditedSum += this.controllingActions[g].HoursCredited;
          this.selfWorkSum += this.controllingActions[g].HoursSelfWork;
        }
        for (let g=0; g < this.additionalWorks.length; g++) {
          if(this.additionalWorks[g].Calc && this.additionalWorks[g].ClassroomWork)
            this.contactSum += this.additionalWorks[g].TypeWorks
              .filter(x => x.ClassroomWork && x.Calc)
              .reduce((sum, current:EducationPlanTypeWorks) => sum + current.Value, 0);
        }
      })
    }
  }

  function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
