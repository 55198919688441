import { DictStudyForm } from "../profile/dictionaries/DictStudyForm.model";
import { LKEducationProgram } from "./LKEducationProgram.model";

export interface LKEducationPlan {
    educationPlanId: number;
    educationPlanExternalId: string;
    jsonData: string;
    educationProgramId: string;
    educationProgram: LKEducationProgram
    dictStudyFormId: string;
    dictStudyForm: DictStudyForm;
}

// WIP
export interface EducationPlanDiscipline {
    DictDisciplineId: string;
    DictCycleId: string;
    DictCycleName: string;
    DictCycleSName: string;
    DepartmentId: string;
    DepartmentName: string;
    DepartmentSName: string;
    ControllingActions: EducationPlanControllingAction[];
    AdditionalWorks: EducationPlanAdditionalWorks[];
}

export interface EducationPlanDetails {

    Id: string;
    DictStudyFormName: string;
    StatusName: number;
    Semesters: EducationPlanSemesters
}

export interface EducationPlanSemesters {
    Id: string;
    Number: number;
    CourseNumber: number;
    SemesterDurationOrNumberSessions: number;
    StartTraining: boolean;
}


export interface EducationPlanTypeWorks {
    Name: string;
    ClassroomWork: boolean;
    Calc: boolean;
    DataType: string;
    SName: string;
    Value: number;
}

export interface EducationPlanControllingAction {
    IsDistributed: boolean;
    HasCourseWork: boolean;
    HasCourseProject: boolean
    HoursContactWork: number;
    HoursSelfWork: number;
    HoursCredited: number;
    Control: number;
    SemesterNumber: number;
    ControllingActions: any;
    DictDisciplineName(DictDisciplineName: any): unknown;
    DictControlActionId: string;
    DictControlActionName: string;
    DictControlActionSName: string;
    SemestrId: string;
    TotalHours: number;
    TotalLaborIntensity: number;
    TypeWorks: EducationPlanTypeWorks[];
}
export interface EducationPlanAdditionalWorks {
  Name: string ;
  ShortName :string;
  ClassroomWork : boolean;
  Calc : boolean;
  TypeWorks: EducationPlanTypeWorks[];
}

export interface EducationGraphLegend {
  name: string;
  symbol: string;
  color: string;
  isInvolvedCalculating: boolean;
  dictWorkCategoryScheduleId?: string;
  dictTrainingLevelId: string;
}
