import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService) {
    }
    
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const token = this.tokenStore.getAuthToken();
      if(token !== null)
      {
        const user = this.jwtHelper.decodeToken(token);
        const roles = user?.role;
        if (token && !this.jwtHelper.isTokenExpired(token) && roles?.includes('admin'))
          return true;
        alert('Недостаточно прав для доступа на эту страницу');
      }
      return false;
  }
  
}
