import {Component, OnInit} from '@angular/core';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import {AlignSettings} from "@progress/kendo-angular-layout/layouts/models";
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { LKEmailService } from 'src/app/services/LKStudent/lkemail.service';
import { LKLanguagesService } from 'src/app/services/LKStudent/lklanguages.service';
import { LKOrdersStudyPeriodService } from 'src/app/services/LKStudent/lkorders-study-period.service';
import { LKPhoneService } from 'src/app/services/LKStudent/lkphone.service';
import { LKStudEduGroupService } from 'src/app/services/LKStudent/lkstud-edu-group.service';
import { LKStudentAcademicStateService } from 'src/app/services/LKStudent/lkstudent-academic-state.service';
import { LKStudentBudgetStateService } from 'src/app/services/LKStudent/lkstudent-budget-state.service';
import { LKStudPlanService } from 'src/app/services/LKStudent/lkstud-plan.service';
import {environment} from "../../../environments/environment";
import {MiddleControlService} from "../../services/LKStudyProgress/middle-control.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {TokenStorageService} from "../../services/token.service";
import {EmailDto, PhonesDto, StudentInfoDto} from "../../models/home/StudentInfoDto.model";
import {StudentCardDisplaySettingService} from "../../services/LKStudent/student-card-display-setting.service";
import {DisplaySettingEnum} from "../../models/home/enums/display-setting.enum";
import {Router} from "@angular/router";
import {DisplaySystemSettingService} from "../../services/LKStudent/display-system-setting.service";
import {
  DisplaySystemSettingEnum,
  DisplaySystemSettingEnumList
} from "../../models/home/enums/display-system-setting.enum";
import {PhoneType} from "../../models/enums/phoneTypeEnumList.enum";
import {DictPhoneType} from "../../models/profile/dictionaries/dictPhoneType.model";
import {DictEmailType} from "../../models/profile/dictionaries/dictEmailType.model";
import {DictPhoneTypesService} from "../../services/contacts/phoneTypes.service";
import {DictEmailTypesService} from "../../services/contacts/emailTypes.service";
import {AllDisplaySystemSetting} from "../../models/home/displaysystemsetting.model";
import {SocialNetworkType} from "../../models/profile/dictionaries/socialNetworkType.model";
import {DictSocialNetworkService} from "../../services/contacts/dictSocialNetwork.service";
import {SystemSettingNotification} from "../../models/home/systemsettingnotification.model";
import {SystemSettingNotificationService} from "../../services/LKStudent/system-setting-notification.service";

@Component({
  selector: 'client-apps-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  public phoneType = PhoneType;
  public phoneTypes: DictPhoneType[] = [];
  public emailTypes: DictEmailType[] = [];
  public socialNetworksTypes: SocialNetworkType[] = [];
  public displaySystemSetting: AllDisplaySystemSetting[] = [];
  public systemSetting = DisplaySystemSettingEnum;
  public systemSettingContactNotification: SystemSettingNotification = new SystemSettingNotification();

  public hasLoaded = false;
  public photoUrl = '';
  public supportEmail = environment.supportEmail;
  public infoMessage = environment.infoMessage;
  public hidden = true;
  public isAdmin = false;

  // Изучаемые языки
  public languagesValue? = "-"
  public studentInfo?: StudentInfoDto;
  public enum = DisplaySettingEnum;
  public systemEnum = DisplaySystemSettingEnum;
  public arrowButton: {[key: string]: boolean} = {};
  public showFilter = false;

  // Выставить true после появления расписания занятий
  public shortInformation = false;
  public align: AlignSettings = {vertical: "top"}
  public currentDate = new Date();
  public dayName = "";

  constructor(
    private studentService: LKStudentService,
    private emailService: LKEmailService,
    private languagesService: LKLanguagesService,
    private ordersStudyPeriodService: LKOrdersStudyPeriodService,
    private phoneService: LKPhoneService,
    private studEduGroupService: LKStudEduGroupService,
    private studentAcademicStateService: LKStudentAcademicStateService,
    private studentBudgetStateService: LKStudentBudgetStateService,
    private studPlanService: LKStudPlanService,
    private middleControlService: MiddleControlService,
    private displaySettingService: StudentCardDisplaySettingService,
    private jwtHelper: JwtHelperService,
    private tokenStore: TokenStorageService,
    private displaySystemSettingService: DisplaySystemSettingService,
    private dictPhoneTypesService: DictPhoneTypesService,
    private dictEmailTypesService: DictEmailTypesService,
    private dictSocialNetworkService: DictSocialNetworkService,
    private systemSettingNotificationService: SystemSettingNotificationService,
    private router: Router,
    ) {
      // Получить текущее название дня недели
      let daysArray = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
      let day = new Date().getDay();
      this.dayName = daysArray[day]
    }

  public gridData = [
    { channel: "Работников всего", conversion: 8232, users: 1539 },
    { channel: "ППС", conversion: 6574, users: 935 },
    { channel: "АУП", conversion: 4932, users: 150 },
    { channel: "Прочие", conversion: 1172, users: 454 },
  ];
  public eventsData = [
    { id: 1, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 2, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 3, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 4, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
  ];

  public examsData = [
    { id: 1, subject: "Психологическое сопровождение пациентов в общей медицинской практике", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
    { id: 2, subject: "Психология, психопатология и психотерапия зависимостей", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
    { id: 3, subject: "Типология личностных расстройств", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
  ];

  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson
  }

    public getCurrentStudent() {
      const changeStudent = Number(localStorage.getItem('changeStudent'));
        this.studentService.getCurrentStudent()
            .subscribe(
                async response => {
                    this.students = response;
                    if(changeStudent) {
                        this.studentModel = this.students[changeStudent-1];
                    }
                    else {
                        this.studentModel = this.students[0];
                    }
                    this.photoUrl = `url('${environment.apiEndpoint}studperson/GetStudPersonPhoto/${this.studentModel.studPersonId}')`;
                    this.getDisplaySettings();
                    this.getDisplaySystemSettings();
                    this.getDisplaySystemSetting();
                    this.getSystemsSettingContactNotification();
                    this.getStudentInfo(`${this.studentModel.externalId}`);
                }
            );
    }
  public getStudentInfo(studentId: string) {
    this.studentService.GetStudentInfo(studentId)
      .subscribe(
        response => {
          this.studentInfo = response;        
        }
      );
  }

  public getDisplaySettings() {
    this.displaySettingService.getStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettingService.displaySettings$.next(response);
        }
      );
  }

  public getDisplaySystemSettings() {
    this.displaySystemSettingService.GetDisplaySettings()
      .subscribe(
        response => {
          this.displaySystemSettingService.displaySettings$.next(response);
        }
      );
  }

  public getDisplaySystemSetting() {
    this.displaySystemSettingService.GetAllSystemDisplaySettings()
      .subscribe(
        response => {
          this.displaySystemSetting = response;
        }
      );
  }

  public getSystemsSettingContactNotification() {
    this.systemSettingNotificationService.GetSystemsSettingContactNotification()
      .subscribe(
        response => {
          this.systemSettingContactNotification = response;
        }
      )
  }

  public showUsedFiltersBySetting(): void {
    this.showFilter = !this.showFilter;
    this.arrowButton = {
      'rotate-arrow-180': this.showFilter,
      'rotate-arrow-0': !this.showFilter
    };
  }

  public isHidden(field: number): boolean {
    let isHidden = false;
    return this.displaySettingService.isHidden(field) || isHidden;
  }

  public systemSettingIsHidden(field: number): boolean {
    let isHidden = false;
    return this.displaySystemSettingService.isHidden(field) || isHidden;
  }

  phoneFormat(input:any) {
    if(input.startsWith(9)) {
     input = "+7" + input;
    }
    if(input.startsWith(8)) {
     input = input.substring(1);
     input = "+7" + input;
    }
    if(input!=null)return input.replace(/^\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5');
  }

  public getMobilePhones(): PhonesDto[] {
    return this.studentInfo?.phones?.filter(phone => phone.dictPhoneTypeId !== this.phoneType.city)
      .sort((a, b) => Number(b.isMain) - Number(a.isMain)) || [];
  }

  public getCityPhones(): PhonesDto[] {
    return this.studentInfo?.phones?.filter(phone => phone.dictPhoneTypeId === this.phoneType.city) || [];
  }

  public getStudentEmails(): EmailDto[] {
    return this.studentInfo?.emails?.sort((a, b) => Number(b.isMain) - Number(a.isMain)) || [];
  }

  public getPhoneTypeName(phoneTypeId?: string): string {
    return this.phoneTypes?.find(phoneType => phoneType.id === phoneTypeId)?.phoneTypeName.toLowerCase() || '';
  }

  public getEmailTypeName(emailTypeId?: string): string {
    return this.emailTypes?.find(emailType => emailType.id === emailTypeId)?.emailTypeName.toLowerCase() || '';
  }

  public getDictSocialNetworkName(dictSocialNetworkId?: string): string {
    return this.socialNetworksTypes?.find(socialType => socialType.dictSocialNetworkId === dictSocialNetworkId)?.socialNetworkName || '';
  }

  public getDomainFromUrl(socialNetworkUrl: string): string {
    return socialNetworkUrl.replace(/https?:\/\//, '').split('/')[0]
      || socialNetworkUrl.replace(/http?:\/\//, '').split('/')[0];
  }

  public getAllPhoneTypes() {
    this.dictPhoneTypesService.getAllPhoneTypes()
      .subscribe(response => {
        this.phoneTypes = response;
      })
  }

  public getAllEmailTypes() {
    this.dictEmailTypesService.getAllEmailTypes()
      .subscribe(response => {
        this.emailTypes = response;
      })
  }

  public getAllDictSocialNetworks() {
    this.dictSocialNetworkService.getAllSocialNetworks()
      .subscribe(
        response => {
          this.socialNetworksTypes = response;
        }
      );
  }

  public toAddContactPage() {
    this.router.navigate(['/addcontact']);
  }

  public isCanAddContact(): boolean {
    return this.displaySystemSetting.filter(
      x => x.type === DisplaySystemSettingEnum.email ||
        x.type === DisplaySystemSettingEnum.mobile_phone ||
        x.type === DisplaySystemSettingEnum.city_phone ||
        x.type === DisplaySystemSettingEnum.socialnetwork
    ).some(x => x.isDisplay === true);
  }

  public toEditContactPage(contactTypeName: string, contactId: string) {
    this.router.navigate(['/editcontact', contactTypeName, contactId]);
  }

  public shouldShowEditButton(addedByStudent: boolean, type: DisplaySystemSettingEnumList): boolean {
    if (!this.isCanAddContact())
      return false;

    const canEditDeaneryContacts = !this.systemSettingIsHidden(this.systemEnum.student_can_edit_deanery_contacts);
    const canEditCurrentContactType = !this.systemSettingIsHidden(type);

    return ((canEditDeaneryContacts || addedByStudent) && canEditCurrentContactType);
  }

  ngOnInit(): void {
    this.getCurrentStudent();
    this.adminInitialize();
    this.getAllPhoneTypes();
    this.getAllEmailTypes();
    this.getAllDictSocialNetworks();
  }

  private adminInitialize(): void {
    let role = [] as string[];
    const token = this.tokenStore.getAuthToken();
    if(token !== null)
    {
      const user = this.jwtHelper.decodeToken(token);
      if (user.role)
        role = user.role;
    }

    if(role.includes('admin'))
      this.isAdmin = true;
  }

  protected readonly DisplaySystemSettingEnum = DisplaySystemSettingEnum;
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
