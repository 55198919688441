<div class="grid-layout-container container"> 
    <div class="title-message m-b15">
        <div class="alerts">   
            Объявления и новости
        </div>
    </div>

    <div class="messages">
      <div class="m-r30" > Всего объявлений и новостей для меня: {{ total }} </div>
      <div class="m-r30 font-message"> Прочитано: {{ firstParametr }}</div>
      <div class="text-blue font-message"> Не прочитано: {{ calculatedParametr }} </div>
    </div>

    <div class="filter-container">
      <kendo-expansionpanel subtitle="Поиск новостей и объявлений " class="back-color" [(expanded)]="expandedState">
          <ng-template kendoExpansionPanelTitleDirective>
              <h2 class="search-title">Поиск новостей и объявлений</h2>
          </ng-template>

          <div class="search-block">
              <div class="search">
                  <div class="search-explanation">Тип</div>
                  <div>
                      <div class="search-information">
                          <div>
                              <label>
                                  <input type="radio" name="messages" [checked]="press" class="real-radio display-none" [value]= 2 [(ngModel)]="type">
                                  <span class="custom-radio"></span>  
                              Все                            
                              </label>
                          </div>
                          <div>
                              <label>
                                  <input type="radio" name="messages" (click)="notpress()" class="real-radio display-none" [value]= 1 [(ngModel)]="type">
                                  <span class="custom-radio"></span>  
                              Новости                            
                              </label>
                          </div>
                          <div>
                              <label>
                                  <input type="radio" name="messages"  (click)="notpress()" class="real-radio display-none" [value]= 0  [(ngModel)]="type">
                                  <span class="custom-radio"></span>  
                              Объявления                            
                              </label>
                          </div>
                      </div>  
                  </div>
              </div>
           
              <div class="search">
                  <div class="search-explanation"> Статус прочтения </div>
                  <div>
                    <div class="search-information">
                          <div>
                              <label>
                                  <input type="radio" name="statusmessages" [checked]="presstatus" class="real-radio display-none" [value]= 0  [(ngModel)]="status">
                                  <span class="custom-radio"></span>  
                              Все                            
                              </label>
                          </div>
                          <div>
                              <label>
                                  <input type="radio" name="statusmessages" (click)="notpresstatus()" class="real-radio display-none" [value]= 1 [(ngModel)]="status">
                                  <span class="custom-radio"></span>  
                              Непрочитанные                            
                              </label>
                          </div>
                          <div>
                              <label>
                                <input type="radio" name="statusmessages" (click)="notpresstatus()" class="real-radio display-none" [value]= 2 [(ngModel)]="status">
                                  <span class="custom-radio"></span>  
                              Прочитанные                            
                              </label>
                          </div>
                    </div>
                </div>
            </div>
            <div class="search">
                <div class="search-explanation">Запрос</div>
                <div class="search-information">
                    <kendo-textbox  class="search-input"
                                    placeholder="Введите запрос"
                                    [clearButton]="true"
                                    name="query"                                                        
                                    [(ngModel)]="query"
                    >
                    </kendo-textbox>
                </div>
            </div>

            <div class="search">
                <div class="search-explanation"> Тег для поиска </div>
                <div class="search-information">
                    <kendo-textbox  class="search-input"
                                    placeholder="Введите тег без #"
                                    [clearButton]="true"
                                    name="tag"                                                        
                                    [(ngModel)]="tag"
                    >
                    </kendo-textbox>
                </div>
            </div>

            <div class="search">
                <div class="search-explanation"> Период публикации </div>
                <div class="search-information w-600 justify-space">
                    <div> 
                            <kendo-datepicker
                                class="m-r10 m-l0"
                                format="dd.MM.yyyy"
                                placeholder="с"
                                [(ngModel)]="start"
                                >
                                <kendo-datetimepicker-messages today="Сегодня"></kendo-datetimepicker-messages>
                            </kendo-datepicker>
                            -
                            <kendo-datepicker
                                class="m-r10"
                                format="dd.MM.yyyy"
                                placeholder="по"
                                [(ngModel)]="finish"
                                >
                                <kendo-datetimepicker-messages today="Сегодня"></kendo-datetimepicker-messages>
                            </kendo-datepicker>
                        </div>
                        <div>
                            <button kendoButton
                            class="m-r10 grey-button"                       
                            (click) = "clearAllFilters()"                       
                            >
                            Очистить
                            </button>
                            <button kendoButton
                            class="blue-button"
                            (click)="findMessage(1)"                      
                            >
                            Применить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </kendo-expansionpanel>
    </div>
    
    <div class="message-list">
               
        <div [ngClass]="{message:  element.isOpened , message_notread: !element.isOpened}" *ngFor="let element of alert" >
            <div [ngClass]="{panel: element.isOpened, panel_notread: !element.isOpened}"></div>
            <div class="data">
                <div class="title-organization">
                    <div class="title-news" [routerLink]="['/show/' , element.id]"> {{ element.title}} </div>
                </div>
                <div [routerLink]="['/show/' , element.id]" class="padding-5">
                    <kendo-editor  [(ngModel)]="element.content" [iframe]="false" [disabled]="true" class="editor" >
                        <kendo-toolbar class="display-none toolbar"></kendo-toolbar>
                    </kendo-editor>
                </div>  
                <div class="from-date-news"> {{ element.authorFio}} </div>
                <div class="from-date-news"> {{ element.published | date:'dd.MM.yyyy HH:mm'}} </div>
            </div>
        </div>
    </div>
    <div class="pages">
        <kendo-datapager *ngIf="total/pageSize > 1"
        [total]="messageCount"
        [pageSize]="pageSize"
        [pageSizeValues]="pageSizes"
        [info]="info"
        [skip]="skip"
        (pageChange)="onPageChange($event)"
        >
        </kendo-datapager>
    </div>
</div>

