import { Component, OnInit } from '@angular/core';
import {NotificationsService} from "../../../../services/Notifications/notifications.service";
import {IotService} from "../../../../services/iot/iot.service";
import {StudentStudyYear} from "../../../../models/iot/student-study-year.model";
import {
  AddElectiveDisciplineStudentCommand, DeleteElectiveDisciplineStudentCommand,
  GetStudentsElectiveDisciplineDto, SignStatementCommand,
  StudentElectiveDiscipline
} from "../../../../models/iot/student-elective-discipline.model";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.scss']
})
export class IotComponent implements OnInit {

  constructor(private notificationService: NotificationsService,
              private iotService: IotService
  )
  { }

  public iot = environment.iot;
  protected studentStudyYears: StudentStudyYear[] = [];
  protected studentElectiveDisciplines: StudentElectiveDiscipline[] = [];
  protected addElectiveDisciplineStudentCommand: AddElectiveDisciplineStudentCommand | undefined;
  public yearAdmission!: number;
  public hasLoaded = false;
  public hasDeadline = false;
  public cantSign = true;
  public openedDialog = false;

  ngOnInit(): void {
    if (this.iot) {
      this.getStudentStudyYears();
    }
  }

  private getStudentStudyYears() {
    this.iotService.getStudentStudyYears().subscribe(
      (next) => {
        this.studentStudyYears = next;
        this.yearAdmission = this.studentStudyYears[0].studyYear;
        this.getStudentElectiveDisciplines(this.yearAdmission);
      }
    )
  }

  public IsCurrentYear() {
    if (this.studentStudyYears.length > 0)
      return this.studentStudyYears.find(x => x.studyYear == this.yearAdmission)?.isCurrentStudyYear === true;
    return true;
  }

  private getStudentElectiveDisciplines(studyYear: number){
    this.iotService.getStudentElectiveDisciplines(studyYear).subscribe(
      (next) => {
        this.studentElectiveDisciplines = next;
        this.checkIfCanSign();
        this.checkStudentDeadlines();
        this.hasLoaded = true;
      }
    )
  }

  public LoadStudentElectiveDiscipline(studyYear: number) {
    this.hasLoaded = false;
    this.getStudentElectiveDisciplines(studyYear);
  }

  hasAnyEnrolled(electiveDisciplineDto: GetStudentsElectiveDisciplineDto[]): boolean {
    return electiveDisciplineDto.some(item => item.hasEnrolled);
  }

  checkStudentDeadlines() {
    this.hasDeadline = this.studentElectiveDisciplines
      .some(item => item.electiveDisciplineDto
        .some(x => x.hasDeadline));
  }

  checkIfCanSign(): void {
    this.cantSign = !this.studentElectiveDisciplines.every(dpv =>
      dpv.electiveDisciplineDto.some(discipline => discipline.hasEnrolled)
    );
  }

  closeDialog() {
    this.openedDialog = false;
  }

  public checkForSameElectiveDisciplineInStudyYear(dataItem: GetStudentsElectiveDisciplineDto) {
    this.addElectiveDisciplineStudentCommand = new AddElectiveDisciplineStudentCommand(dataItem.electiveDisciplineId, this.yearAdmission);
    if (dataItem.hasSameElectiveDisciplineInStudyYear) {
      this.openedDialog = true;
    } else {
      this.enroll();
    }
  }

  public enroll() {
    this.iotService.addStudentElectiveDiscipline(this.addElectiveDisciplineStudentCommand!).subscribe(
      (next) => {
        this.openedDialog = false;
        this.getStudentElectiveDisciplines(this.yearAdmission);
        this.notificationService.showSuccess('Сохранено');
      }
    )
  }

  public unroll(dataItem: GetStudentsElectiveDisciplineDto) {
    const deleteElectiveDisciplineStudentCommand = new DeleteElectiveDisciplineStudentCommand(dataItem.electiveDisciplineStudentId);
    this.iotService.deleteStudentElectiveDiscipline(deleteElectiveDisciplineStudentCommand).subscribe(
      (next) => {
        this.getStudentElectiveDisciplines(this.yearAdmission);
        this.notificationService.showSuccess('Сохранено');
      }
    )
  }

  public signStatement() {
    const signStatementCommand = new SignStatementCommand(this.yearAdmission);
    this.iotService.signStatement(signStatementCommand).subscribe(
      (next) => {
        this.getStudentElectiveDisciplines(this.yearAdmission);
        this.notificationService.showSuccess('Сохранено');
      }
    )
  }

}
