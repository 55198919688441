import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {DictMark} from "../../../models/mfc/dicts/mark.model";
import {DictMarkValue} from "../../../models/enums/markvalue.enum";

@Injectable({
  providedIn: 'root'
})
export class DictMarkService {
  private readonly baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.externalDict}/DictMark/`;

  constructor(private http: HttpClient) {}

  public getMarks() {
    return this.http.get<DictMark[]>(this.baseUrl).pipe(
      map(data => [{id: null, name: 'Прослушано', value: DictMarkValue.None}, ...data])
    );
  }
}