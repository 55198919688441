import {ApplicationStatusesEnum} from '../enums/application-statuses.enum';
import {ConstructorDocument} from '../applicationConstructor/constructor-document.model';
import {TabsSettings} from '../applicationConstructor/tabs-settings.model';
import {KendoProperties} from '../enums/kendo-properties.enum';
import {PropertyValue} from './application-property-value.model';
import {Dict} from "../dict.model";
import {ApplicationApprovalSettings} from './approvalList.model';
import {EnableDisciplineTable} from "../enums/enable-discipline-table.enum";

export class CreateApplicationMainForm {
  category: string = '';
  type: string = '';
  dictTypeReceiptId: string = '';
  relatedApplicationIds: string[] = [];
}

export class EditApplicationMainForm {
  relatedApplicationIds?: string[] = [];
}

export interface GetApplication {
  externalId: string;
  applicationNumber: number;
  applicationConstructorId: string;
  dictReceiptType: Dict;
  receiptTypeExplanation: string;
  student: Student;
  statusEnum: ApplicationStatusesEnum;
  applicationStatusInfo: StatusInfo;
  notificationCount: number;
  registrationNumber?: RegistrationNumber;
  issueDate?: Date;
  applicationCategoryName: string;
  applicationTypeName: string;
  approvalSettings: ApplicationApprovalSettings[];
  relatedApplications: RelatedApplication[];
  documents: ConstructorDocument[];
  tabs: TabsSettings[];
  enableDisciplineTable: EnableDisciplineTable;
  disciplineTableSemesters: number[];
}

export interface TypeReceiptExplanation {
  dictTypeReceiptId: string;
  text: string;
}

export interface StatusInfo {
  statusUpdatedAt: Date;
  statusChangePersonId?: string;
  personFIO?: string;
  statusChangeStudentId?: string;
  studentFIO?: string;
}

export interface Student {
  id?: string;
  fio: string;
}

export interface RegistrationNumber {
  registrationNumber?: number;
  registrationNumberLitera?: string;
}

export interface RelatedApplication {
  id: string;
  applicationNumber: string;
}

export interface ApplicationNumber {
  applicationId: string;
  applicationNumber: string;
}

export interface ApplicationForm {
  applicationConstructorId?: string;
  dictTypeReceiptId?: string;
  relatedApplicationIds?: string[];
  disciplineTableSemesters?: number[];
  applicationPropertiesValueDto: ApplicationFormProperty[];
}

export interface ApplicationTabData {
  index: number;
  applicationPropertiesValueDto: ApplicationFormProperty[];
}

export interface ApplicationFormProperty {
  applicationConstructorPropertyId: string;
  propertyType: KendoProperties;
  value: PropertyValue|File[]|null;
}

export interface GroupPropertyForm {
  properties: PropertyForm[];
}

export interface PropertyForm {
  externalId: string;
  propertyTypeEnum: KendoProperties;
  propertyValue: PropertyValue|File[]|null;
}
