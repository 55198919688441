import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from '@progress/kendo-file-saver';
import { FileRestrictions } from "@progress/kendo-angular-upload";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import {AchievementStatusEnum, DictAchievementStatus, DictDirectionActivity, WorkType, WorkTypeTree} from '../../../models/portfolio/dict.model';
import { Achievement, AchievementFile, AchievementList } from '../../../models/portfolio/achievement.model';
import { environment } from "../../../../environments/environment";
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import {Guid} from "guid-typescript";
import {Observable, of} from "rxjs";
import {PortfolioService} from "../../../services/LKPortfolio/portfolio.service";
import {DictService} from "../../../services/LKPortfolio/dict.service";
import {NotificationsService} from "../../../services/Notifications/notifications.service";
import {DateFromUTCAsLocal, DateToString, monthWord, TimeZoneFix} from '../../../helpers/date-helper';
import {getErrorMessage} from "../../../helpers/errorHandle-helper";
import {LKStudentService} from "../../../services/LKStudent/lkstudent.service";
import {LKStudPerson} from "../../../models/profile/lkStudPerson.model";
import {LKStudent} from "../../../models/profile/lkstudent.model";
import {DictAchievementStatusEnum} from "../../../models/portfolio/enums/dict-achievement-status-enum";
import {openRemoveApprovalDialog, openRemoveDialog} from "../../../helpers/dialog-helper";

@Component({
  selector: 'app-advanced-achievements',
  templateUrl: './advanced-achievements.component.html',
  styleUrls: ['./advanced-achievements.component.scss']
})
export class AdvancedAchievementsComponent implements OnInit {

  //#region VAR

  public editable = false;
  public achievementStatusReturned = false;
  public loading = false;
  public editMode = false;
  public edited = false;
  public achievements: Achievement[] = [];
  public filteredAchievements: any[] = [];
  public directionActivities: DictDirectionActivity[] = [];
  public directionActivitiesByFilter: DictDirectionActivity[] = [];
  public statuses: DictAchievementStatus[] = [];
  public types: WorkType[] = [];
  public typesByFilter: WorkType[] = [];
  public workTypesTree: WorkTypeTree[] = [];
  public selectedWorkTypesTree?: WorkTypeTree;
  public achievementStatusEnum = AchievementStatusEnum;
  public mark = 0;
  public markWithCoefficient = 0;
  public coefficient = 1;
  public invalidFileExtensionText = 'Недопустимый формат файла. Используйте только .pdf файлы';
  public statusEnum = DictAchievementStatusEnum;

  //#region File

  public restrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: environment.portfolio.maxFileSize,
    allowedExtensions: [".pdf"],
  };
  public maxFileSizeMessage = `Размер файла должен быть не более ${Math.round(environment.portfolio.maxFileSize/1024/1024)} МБ`;
  public fileUploaded = false;
  public fileUploadedList: AchievementFile[] = [];
  public fileUploads?: Array<File>;

  //#endregion

  public editForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    name: new UntypedFormControl(),
    dictWorkTypeId: new UntypedFormControl(),
    coefficient: new UntypedFormControl(1),
    dateEnd: new UntypedFormControl(),
    dateStart: new UntypedFormControl(),
    markWithCoefficient: new UntypedFormControl(),
    mark: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    dictAchievementStatusName: new UntypedFormControl(),
    dictAchievementStatusId: new UntypedFormControl(),
    directionActivityId: new UntypedFormControl(),
    files: new UntypedFormControl(),
  });

  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson,
    filialId: ""
  }

  //#endregion

  constructor(private portfolioService: PortfolioService,
              private studentService: LKStudentService,
              private dictService: DictService,
              private activateRoute: ActivatedRoute,
              private notificationService: NotificationsService,
              private dialogService: DialogService
              )
  { }

  //#region Lifecycle methods

  ngOnInit(): void {
    this.getCurrentStudent();
  }

  public async getCurrentStudent() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    this.studentService.getCurrentStudent()
      .subscribe(
        async response => {
          this.students = response;
          if(changeStudent) {
            this.studentModel = this.students[changeStudent-1];
          }
          else {
            this.studentModel = this.students[0];
          }
          this.getDirectionActivities();
          this.getAchievements();
          this.getWorkTypes();
          this.getStatuses();
        }
      );
  }

  //#endregion

  //#region Data

  public getAchievements() {
    this.loading = true;
    this.portfolioService.getAdvancedAchievements({ studentId: this.studentModel.externalId }).subscribe({
      next: response => {
        this.achievements = response?.map((item: Achievement) => {
          item.hideAchievements = item.achievementList.filter((achievement: AchievementList) =>
            achievement.dateEnd && achievement.dateEnd < item.achievementPeriod.pretendentStartDate)?.length > 0 ? false : undefined;
          item.achievementList.map((listItem: AchievementList) => {
            listItem.hide = listItem.dateEnd != undefined && listItem.dateEnd <= item.achievementPeriod.pretendentStartDate;
            listItem.dateEnd = DateFromUTCAsLocal(listItem.dateEnd);
            listItem.dateStart = DateFromUTCAsLocal(listItem.dateStart);
            return listItem;
          });
          return item;
      });
        this.filteredAchievements = this.achievements;
      },
      error: err => {
        this.notificationService.showError(getErrorMessage(err));
      },
      complete: () => this.loading = false
    });
  }

  public getDirectionActivities() {
    this.dictService.getDirectionActivities().subscribe(
      response => {
        this.directionActivities = response;
        this.getDirectionActivitiesByFilter();
      }
    );
  }

  public getStatuses() {
    this.dictService.getStatuses().subscribe(
      response => {
        this.statuses = response;
      })
  }

  public getWorkTypes() {
    this.dictService.getTypes().subscribe(
      response => {
        this.types = response ?? [];
      })
  }

  public getDirectionActivitiesByFilter() {
    this.directionActivitiesByFilter = this.directionActivities.filter((x: DictDirectionActivity) => x.filialId == this.studentModel.filialId);
  }

  public getWorkTypesByFilter(directionActivityId: string) {
    this.typesByFilter = this.types.filter((item: WorkType) => item.directionActivityId == directionActivityId);
    this.workTypesTree = this.processFlatData();
  }

  //#endregion

  //#region Handlers

  public deleteAchievement(): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${this.editForm.value.name}`);
    dialog.result.subscribe((result ) => {
      if (result instanceof DialogCloseResult) {}
      else {
        if (result.text == "Да") {
          this.portfolioService.deleteAchievement(this.editForm.value.id).subscribe({
            next: () => {
              this.notificationService.showSuccess('Успешно')
              this.getAchievements();
              this.closeEditForm();
            },
            error: err => this.notificationService.showError(getErrorMessage(err))
          })
        }
      }
    });
  }
  //#endregion

  //#region Download

  public downloadFile(file: { id: string, name: string }) {
    this.portfolioService.downloadAchievementFile(file.id).subscribe({
      next: response => {
        const blob:any = new Blob([response], { type: `application/pdf; charset=utf-8` });

        saveAs(blob, file.name);
      },
      error: err => this.notificationService.showError(err.status == 404 ? 'Файл не найден' : getErrorMessage(err))
    });
  }

  //#endregion

  //#region EditForm

  public addAchievement(activityId: string) {

    this.editMode = true;
    this.getDirectionActivitiesByFilter();
    this.getWorkTypesByFilter(this.editForm.value.directionActivityId);
    this.resetForm();
    this.editForm.reset();
    this.editForm.get('directionActivityId')?.setValue(activityId);
    this.editForm.get('dateStart')?.setValue(new Date());
    this.getWorkTypesByFilter(activityId);
    this.CheckRightsForEditing();
  }

  public showDeleteButton(){
    if (this.editForm.value.dictAchievementStatusName == undefined){
      return false;
    }
    let statusName = this.editForm.value.dictAchievementStatusName.split(" ")[0];
    if (statusName == "Добавлено")
      return true;
    return false;
  }

  getAchievementsByActivityId(activityId: string): any {
    if (this.filteredAchievements != undefined) {
      return this.filteredAchievements.filter(achievement => achievement.directionActivityId === activityId);
    }
    return [];
  }

  public closeEditForm() {
    this.editMode = false;
    this.resetForm();
  }

  public formChanged()
  {
    this.edited = true;
  }

  public saveReturnedAchievement() {
    if (!this.fileUploaded || !this.editForm.valid) {
      this.notificationService.showError('Заполните обязательные поля');
      return;
    }

    const formData = new FormData();

    if (this.editForm.value.id) formData.append(`Id`, this.editForm.value.id);
    formData.append(`Name`, this.editForm.value.name);
    formData.append(`StudentId`, this.studentModel.externalId);
    formData.append(`DirectionActivityId`, this.editForm.value.directionActivityId);
    formData.append(`DictWorkTypeId`, this.editForm.value.dictWorkTypeId ?? '');
    formData.append(`Coefficient`, this.editForm.value.coefficient);
    formData.append(`DateStart`, TimeZoneFix(this.editForm.value.dateStart).toISOString());
    formData.append(`DateEnd`, TimeZoneFix(this.editForm.value.dateEnd).toISOString());
    formData.append(`Comment`, this.editForm.value.comment ?? '');
    formData.append(`InReturnedStatus`, this.achievementStatusReturned.toString());

    this.fileUploads?.forEach((_) => {
      formData.append(`Files`, _, _.name);
    });

    const dialog: DialogRef = openRemoveApprovalDialog(this.dialogService, `${this.editForm.value.name}`);
    dialog.result.subscribe((result ) => {
      if (result instanceof DialogCloseResult) {}
      else {
        if (result.text == "Да") {
          this.portfolioService.putAchievement(formData).subscribe({
            next: () => {
              this.getAchievements();
              this.closeEditForm();
              this.notificationService.showSuccess("Обновлено");
            },
            error: () => this.notificationService.showError('Не удалось обновить достижение')
          })
        }
      }
    });
  }

  public saveAchievement() {

    if (!this.fileUploaded || !this.editForm.valid) {
      this.notificationService.showError('Заполните обязательные поля');
      return;
    }

    const formData = new FormData();

    if (this.editForm.value.id) formData.append(`Id`, this.editForm.value.id);
    formData.append(`Name`, this.editForm.value.name);
    formData.append(`StudentId`, this.studentModel.externalId);
    formData.append(`DirectionActivityId`, this.editForm.value.directionActivityId);
    formData.append(`DictWorkTypeId`, this.editForm.value.dictWorkTypeId ?? '');
    formData.append(`Coefficient`, this.editForm.value.coefficient);
    formData.append(`DateStart`, TimeZoneFix(this.editForm.value.dateStart).toISOString());
    formData.append(`DateEnd`, TimeZoneFix(this.editForm.value.dateEnd).toISOString());
    formData.append(`Comment`, this.editForm.value.comment ?? '');
    formData.append(`InReturnedStatus`, false.toString());

    this.fileUploads?.forEach((_) => {
      formData.append(`Files`, _, _.name);
    });

    this.editForm.value.id
      ? this.portfolioService.putAchievement(formData).subscribe({
        next: () => {
          this.getAchievements();
          this.closeEditForm();
          this.notificationService.showSuccess("Обновлено");
          },
        error: () => this.notificationService.showError('Не удалось обновить достижение')
      })
      : this.portfolioService.postAchievement(formData).subscribe({
          next:() => {
            this.getAchievements();
            this.closeEditForm();
            this.notificationService.showSuccess("Сохранено");
          },
          error:() => this.notificationService.showError('Не удалось сохранить изменения')
      });
  }

  public changeStatus(statusEnum: number) {
    if(this.edited) {
      this.notificationService.showError('Необходимо сохранить изменения!');
    } else {
      const status = {
        dictAchievementStatusId: this.statuses.find((item:any) => item.statusEnum===statusEnum)?.id,
        achievementId: this.editForm.get('id')?.value,
        achievementStatusDate: null,
        isMigrationData: false
      };
      this.portfolioService.postStatus(status).subscribe({
        next: () => {
          this.getAchievements();
          this.closeEditForm()
          this.notificationService.showSuccess("Статус изменен");
          },
        error: () => this.notificationService.showError('Не удалось изменить статус')
      })
    }
  }

  public resetForm() {
    this.edited = false;
    this.fileUploaded = false;
    this.fileUploadedList = [];
    this.fileUploads = [];
    this.editForm.reset();
  }

  //#region File

  public fileSelected() {
    this.edited = true;
    this.fileUploaded = true;
    this.fileUploadedList = [];
  }

  public fileRemoved() {
    this.edited = true;
    this.fileUploaded = false;
  }

  //#endregion

  public children = (dataItem: any): Observable<any[]> => {
    return of(dataItem.items)
  };
  public hasChildren = (dataItem: any) : boolean => {
    return !!dataItem.items;
  }

  public valueChangeDirectionActivity(value: string) {
    this.edited = true;
    this.getWorkTypesByFilter(value);
  }

  public valueChangeType(value: string) {
    this.edited = true;
    this.mark = this.typesByFilter.find((el: WorkType) => el.id == value)?.mark || 0;
    this.calculateMark();
  }

  public onChangeCoefficient(): void {
    this.calculateMark()
    this.edited = true;
  }

  public achievement = {
    dateEnd: new Date(),
    dateStart: new Date(),
    dictAchievementStatusName: "",
    coefficient: 1,
    mark: 0,
  };

  public viewHandler({dataItem}: CellClickEvent): void {
    if (dataItem.files && dataItem.files.length) {
      this.fileUploaded = true;
      this.fileUploadedList = dataItem.files;
    }
    this.getDirectionActivitiesByFilter();
    this.getWorkTypesByFilter(dataItem.directionActivityId);
    this.editMode = true;
    this.achievement = JSON.parse(JSON.stringify(dataItem));
    if (dataItem.dictAchievementStatusName) {
      const date = dataItem.dictAchievementStatusDate ? `(${DateToString(dataItem.dictAchievementStatusDate, 'dd.MM.yyyy HH:mm')})`: '';
      this.achievement.dictAchievementStatusName = `${dataItem.dictAchievementStatusName} ${date}`;
    }
    this.achievement.dateEnd = new Date(dataItem.dateEnd);
    this.achievement.dateStart = new Date(dataItem.dateStart);
    this.coefficient = this.achievement.coefficient;
    this.mark = this.achievement.mark;
    this.calculateMark();
    this.editForm.reset(this.achievement);
    this.selectedWorkTypesTree = this.findWorkTypeTreeById(this.editForm.value.dictWorkTypeId);
    this.CheckRightsForEditing();
    this.CheckForAchievementReturnedStatus();
  }

  private findWorkTypeTreeById(id: string, tree: WorkTypeTree[] = this.workTypesTree): WorkTypeTree | undefined {
    for (const node of tree) {
      if (node.id === id) {
        return node;
      }
      if (node.items) {
        const found = this.findWorkTypeTreeById(id, node.items);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  private CheckRightsForEditing() {
    this.editable = false;
    if (this.editForm.value.dictAchievementStatusName == undefined)
      this.editable = true;

    let neededAchievementStatusForEditing = this.statuses.filter(_ => _.id == this.editForm.value.dictAchievementStatusId)[0];

    if (neededAchievementStatusForEditing?.statusEnum == this.statusEnum.added || neededAchievementStatusForEditing?.statusEnum == this.statusEnum.returned_for_revision)
      this.editable = true;
  }

  private CheckForAchievementReturnedStatus() {
    this.achievementStatusReturned = false;
    let currentAchievement = this.statuses.filter(_ => _.id == this.editForm.value.dictAchievementStatusId)[0];

    if (currentAchievement.statusEnum == this.statusEnum.returned_for_revision)
      this.achievementStatusReturned = true;
  }

  private processFlatData(id: string | null = null): WorkTypeTree[] {
    const tree: WorkTypeTree[] = [];
    this.typesByFilter.filter(item => item.parentId === id)
      .forEach(child => {
        const data: WorkTypeTree = {
          id: child.id,
          name: child.name,
          mark: child.mark,
        };
        if (this.typesByFilter.find(item => item.parentId === child.id)) {
          data.items = this.processFlatData(data.id);
        }

        if (id || data.items?.length) {
          tree.push(data);
        }
      });
    return tree;
  }

  public calculateMark() {
    this.markWithCoefficient = Math.round(this.mark * this.coefficient * 100) / 100;
  }

  public cancelEdit() {
    this.editForm.reset();
    this.editMode = false;
  }

  public hidePast(directionActivityId: any, hideAchievements: boolean) {
    this.achievements.map((item: Achievement) => {
      if (item.directionActivityId == directionActivityId)
        item.hideAchievements = !hideAchievements;
      return item;
    });
    this.filteredAchievements = deepCopy(this.achievements);
    this.filteredAchievements = this.filteredAchievements.map((item: Achievement) => {
        if (item.hideAchievements)
          item.achievementList = item.achievementList.filter((listItem: AchievementList) => !listItem.hide) ?? [];
      return item;
    });
  }

  public getDate(value: string) {
    const date = new Date(value);
    return date.getUTCDate() + " " + monthWord(date.getMonth()) + " " + date.getUTCFullYear();
  }
}
